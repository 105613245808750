@import '../../../styles/global.scss';
.thoughtfully_terms_sec,
.thoughtfully_privacy_sec,
.cookie_sec{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    z-index: 1000;
    .terms_content,
    .privacy_content,
    .cookie_content{
        text-align: left;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        border-radius: 20px;
        width: 80%;
        max-width: 800px;
        padding: 3vw 4vw;
        max-height: 500px;
        overflow-y: auto;
        font-family: 'MontserratLight';
        color: #000000;
        font-size: 16px;
        .content_in{
            overflow-y: auto;
        }
        h2{
            margin-bottom: 40px;
            @include for-mobile-portrait-screen{
                font-size: 6vw;
                line-height: 8vw;
            }
        }
        h4{
            @include for-mobile-portrait-screen{
                font-size: 4.5vw;
                line-height: 8vw;
            }
        }
        h2, h4{
            font-family: 'MontserratBold';
        }
        p{
            b{
                font-family: 'MontserratBold';
            }
        }
    }
    .close {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 32px;
        height: 32px;
        opacity: 1;
        cursor: pointer;
        transition: transform linear 0.1s;
      }
      .close:hover {
        opacity: 1;
        transform: scale(1.1);
      }
      .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 5px;
        background-color: #ffffff;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
}