.team_panel{
    background-color: #684d94;
    position: fixed;
    bottom: 40px;
    font-family: 'MontserratRegular';
    font-size: 17px;
    color: #ffffff;
    z-index: 10;
    // box-shadow: 1px 1px 5px #d6d6d6;
    width: 80%;
    max-width: 700px;
    text-align: center;
    padding: 20px 0;
    border-radius: 12px;
    left: 50%;
    transform: translate(-50%, 200px);
    transition: transform cubic-bezier(.72, 0, .28, 1) 1s;
    &.active{
      transform: translate(-50%, 0);
    }
    z-index: 1000;
}