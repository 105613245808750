@import './global.scss';

.avatar_sec{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    margin: auto;
   
    .avatar_title{
        font-family: 'MontserratRegular';
        font-size: 3vh;
        width: 80%;
        margin: 5vh auto;
        text-align: center;
        color: #000000;
        position: relative;
        .bold{
            font-family: 'MontserratBold';
        }
        @include for-mobile-portrait-screen{
            font-size: 4.5vw;
            margin: 3vw auto;
        }
        @include for-tab-portrait-screen{
            font-size:3.75vw;
            margin: 3vw auto;
        }
    }
    .avatars{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
       
        max-width: 50vh;
        margin: auto;
        margin-bottom: 2vh;
        @include for-mobile-portrait-screen{
            margin-bottom: 2vw;
            max-width: none;
        }
        .profile{
            cursor: pointer;
            margin: 0 0 1vh 0;
            @include for-mobile-portrait-screen{
                margin: 0 0 2vw 0;
            }
            &:nth-child(3n+2){
                margin: 0 2vh;
                @include for-mobile-portrait-screen{
                    margin: 0 4vw;
                }
            }
           
        }
    }
    #readyBtn{
        position: relative;
        width: 70%;
        margin: auto;
        max-width: 350px;
        border-radius: 15px;
        font-family: 'MontserratBold';
        color: #000000;
        background-color: #ffed8d;
        font-size: 3vh;
        line-height: 8vh;
        text-align: center;
        cursor: pointer;
        &.disabled{
            opacity: 0.25;
            cursor: none;
        }
        @include for-mobile-portrait-screen{
            font-size: 5vw;
            line-height: min(16vw,9vh);
        }
        @include for-tab-portrait-screen{
            font-size: 4vw;
            line-height: min(16vw,9vh);
        }
    }
}