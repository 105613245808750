@import '../global.scss';

.report_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .middle_area{
            .head{
                .title{
                    color: #ffffff !important;
                }
                .right{
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                    }
                    ::placeholder{
                        color: #ffffff !important;
                    }
                }
            }
            .middle_content{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
                .activity_head{
                    .left{
                        .activity_title,
                        .activity_info{
                            color: #ffffff !important;
                        }
                        
                    }
                }
                .report{
                    .card-title{
                        background-color: #282828 !important;
                        color: #ffffff;
                    }
                    .card-collapse, .list-group-item{
                        background-color: #282828 !important;
                        color: #ffffff;
                    }
                   
                }
            }
        }
    }
    .middle_area{
        position: relative;
        width: calc(100% - 230px);
        &.full_size{
            width: calc(100% - 50px);
          }
          @include dashboard-landscape-limit{
            width: calc(100% - 90px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // @include dashboard-landscape-limit{
            //     flex-direction: column;
            // }
            .left{
                .title{
                    font-family: 'MontserratBold';
                    color: #000000;
                    font-size: 30px;
                }
            }
            .right{
                display: flex;
                flex-direction: row;
                // @include dashboard-landscape-limit{
                //     justify-content: space-between;
                //     margin: 10px 0;
                // }
                input{
                    position: relative;
                    background-color: #ffffff;
                    border: none;
                    padding: 10px 20px;
                    font-family: 'MontserratLight';
                    border-radius: 30px;
                    outline: none;
                    font-size: 16px;
               
                    width: 200px;
                    box-shadow: 1px 1px 13px #d6d6d6;

                }
                .button{
                    margin-left: 20px;
                    position: relative;
                    .label{
                        width: 170px;
                    }
                }
            }
        }
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 100px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            .reports_mini{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .activity_head{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 40px;
                .left{
                    .activity_title{
                        font-family: 'MontserratBold';
                        color: #000000;
                        font-size: 20px;
                        .sessionID{
                            font-family: 'MontserratRegular';
                            color: #684d94;
                            font-size: 14px;
                            margin-left: 20px;
                        }
                    }
                    .activity_info{
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 14px;
                    }
                }
                .right{
                    display: flex;
                    flex-direction: row;
                    // width: 400px;
                    align-items: center;
                   .breadcrumb{
                    display: flex;
                    flex-direction: row;
                    margin-right: 20px;
                   *{
                    color: #684d94;
                    display: ruby;
                   }
                   }
                   .button{
                    .label{
                        width: initial;
                        font-size: 16px;
                    }
                   }
                }
            }
            .report{
                &.disabled{
                    pointer-events: none;
                }
                width: 100%;
                margin-bottom: 10px;
                @include for-mobile-portrait-screen{
                    margin-bottom: 0.5vw;
                }
                .list-group-item{
                    
                    border-radius: 20px;
                    @include for-mobile-portrait-screen{
                        border-radius: 10vw;
                    }
                    background-color: #f4f4f4;
                    .card-title {
                        cursor: pointer;
                        background-color: #f4f4f4;
                        margin: 0;
                        font-family: 'MontserratRegular';
                        padding: 20px 20px;
                        overflow: hidden;
                        
                        border-radius: 15px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                       h2{
                        font-size: 16px;
                        line-height: 20px;
                        text-align: left;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 80%;
                        overflow: hidden;
                       }
                      }
                      .card-collapse {
                        overflow: hidden;
                        transition: height 0.3s ease-out;
                        background-color: #f4f4f4;
                        border-radius: 20px;
                        .card-body{
                            color: #ffffff;
                            padding: 20px;
                            font-size: 16px;
                        }
                      }
                }
                .distribution_sec{
                    background-color: #dddddd;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    width: 100%;
                    margin-bottom: 10px;
                    align-items: center;
                    border-radius: 15px;
                    width: calc(100% - 40px);
                    padding: 10px 20px;
                    .answer{
                        width: 25%;
                        font-family: 'MontserratRegular';
                        color: #000000;
                        text-align: left;
                        font-size: 16px;
                    }
                    .strength{
                        width: 25%;
                        font-family: 'MontserratLight';
                        color: #000000;
                        text-align: left;
                        font-size: 14px;
                    }
                    .progress{
                        width: 50%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        
                        .progressBar{
                            width: 80%;
                            margin-right: 10px;
                            
                        }
                        .label{
                            font-family: 'MontserratRegular';
                            color: #000000;
                            text-align: left;
                            font-size: 14px;
                        }
                    }
                }
                .timeTaken{
                    background-color: #ffffff;
                    width: calc(100% - 40px);
                    padding: 20px 20px;
                    border: 1px solid #dddddd;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .title{
                        font-family: 'MontserratBold';
                        color: #000000;
                        text-align: left;
                        font-size: 16px;
                        margin-right: 30px;
                    }
                    .time_sec{
                        display: flex;
                        flex-direction: column;
                        width: 200px;
                        margin-left: 50px;
                        .head{
                            font-family: 'MontserratLight';
                            color: #000000;
                            // text-align: left;
                            font-size: 12px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
        
    }
    .right_area{
        position: relative;
        width: 210px;
        padding: 15px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px #d6d6d6;
        border-radius: 10px;
        overflow-y: auto;
   
    }
    ::-webkit-scrollbar {
        display: none;
    }
}
::placeholder{
    color: #000000 !important;
}