.control_panel{
    position: absolute;
    top:50px;
    right: 20px;
    background-color: #ffffff;
    z-index: 10;
    border-radius: 10px;
    box-shadow: 1px 1px 13px #d6d6d6;
    overflow: hidden;
    &.darkMode{
        background-color: #181818;
        box-shadow: 1px 1px 13px #181818;
    }
    z-index: 1001;
}