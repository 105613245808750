#rotateScr{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    &.dark{
        .rotateLabel{
            color: #ffffff !important;
        }
    }
    .rotateIcon{
        position: absolute;
        top:50%;
        left: 50%;  
        width: 100%;
       
        transform: translate(-50%, -50%);
        // background-color: #ffffff;
        svg{
            position: relative;
            max-width: 250px;
            left: 50%;
            transform: translateX(-50%);
            margin: auto;
        }
        .rotateLabel{
            position: relative;
            text-align: center;
            top: -40px;
            width: 70%;
            margin:auto;
            font-family: 'MontserratLight';
            color: #000000;
            font-size: 16px;
            &.tablet{
                top: -10px;
            }
        }
    }
  
}