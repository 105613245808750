.nav{
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    overflow: hidden;
    background-color: #000000;
    position: relative;
    .nav_active{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffed8d;
      width: 70%;
      height: 70%;
      border-radius: 50%;
    }
    .icon{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120%;
      height: 120%;
    }
}