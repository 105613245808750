@import './global.scss';
.bg_cover{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
    img{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.error_title{
    font-family: 'MontserratBold';
    font-size: 5vh;
    color: #000000;
    position: absolute;
    top:50%;
    width: 100%;
    text-align: center;
    @include for-mobile-portrait-screen{
        font-size: 8vw;
    }
}