
.right_area{
    position: relative;
    width: 210px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px #d6d6d6;
    border-radius: 10px;
    overflow-y: auto;
    &.darkMode{
        background-color: #181818 !important;
        box-shadow: 1px 1px 5px #181818 !important;
        .recent_title{
            .label{
                color: #ffffff;
            }
        }
    }
    .recent_title{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        .icon{
            position: relative;
            width: 40px;
            margin-right: 5px;
            display: flex;
            img{
                position: relative;
                width: 100%;
            }
        }
        .label{
            font-family: 'MontserratBold';
            color: #000000;
            font-size: 20px;
        }
        
    }
    .thoughtfully_mini{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
        
    }
}