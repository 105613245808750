@import './global.scss';

.start_sec{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    margin: auto;
    align-items: center;
    .role{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #dadada;
        border-radius: 20px;
        justify-content: flex-start;
        padding: 1vh 4vh;
        max-width: 500px;
        margin: auto;
        @include for-mobile-portrait-screen{
            padding: 2vw 4vw;
        }
        .role_icon{
            position: relative;
            width: 6vh;
            margin-right: 10%;
            @include for-mobile-portrait-screen{
                width: 10vw;
            }
            img{
                position: relative;
                width: 100%;
            }
        }
        .label{
            font-family: 'MontserratBold';
            font-size: 2.5vh;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
            }
            position: relative;
        }
        margin-bottom: 2vw;
    }
    .students{
        
        padding: 2vh 2vh;
        border-radius: 20px;
        max-height: 30vh;
        overflow-y: auto;
        margin-bottom: 2vh;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: calc(100% - 4vh);
        max-width: 900px;
        @include for-mobile-portrait-screen{
            padding: 2vw 2vw;
            width: calc(100% - 4vw);
            max-width: none;
            flex-wrap: nowrap;
            flex-direction: column;
        }
        .student{
            background-color: rgba(255, 255, 255, 0.65);
            position: relative;
            width: calc(100% - 4vh);
            max-width: 400px;
            margin-bottom: 10px;
            // border-bottom: 1px solid #dadada;
            padding: 2vh 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // margin: auto;
            border: 1px solid #dadada;
            padding: 2vh 2vh;
            border-radius: 10px;
            align-items: center;
            @include for-mobile-portrait-screen{
                padding: 2vw 2vw;
                width: calc(100% - 4vw);
                max-width: none;
            }
            .close{
                position: relative;
                width: 1.5vh;
                height: 1.5vh;
                padding: 1.5vh;
                border-radius: 50%;
                overflow: hidden;
                background-color: #f26d7d;
                display: flex;
                cursor: pointer;
                @include for-mobile-portrait-screen{
                    width: 2.5vw;
                    height: 2.5vw;
                    padding: 2.5vw;
                }
                img{
                    position: relative;
                    width: 100%;
                }
            }
            &:last-child{
                // border: none;
                margin-bottom: 0;
            }
        }
    }
    .joined_cnt{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3vh 5vh;
        background-color: #000000;
        margin: 10px 0;
        border-radius: 30px;
        max-width: 400px;
        width: 80%;
        margin: 10px auto;
        @include for-mobile-portrait-screen{
            padding: 5vw 8vw;
        }
        @include for-tab-portrait-screen{
            padding: 4vw 6vw;
            margin: 20px auto;
        }
        .label{
            font-family: 'MontserratBold';
            color: #ffffff;
            font-size: 3vh;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
            }
            @include for-tab-portrait-screen{
                font-size: 3vw;
            }
        }
        .cnt{
            font-family: 'MontserratBold';
            color: #ffed8d;
            font-size: 3vh;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
            }
            @include for-tab-portrait-screen{
                font-size: 3vw;
            }
        }
    }
    #startBtn{
        position: relative;
        width: 70%;
        margin: auto;
        max-width: 350px;
        border-radius: 15px;
        font-family: 'MontserratBold';
        color: #000000;
        background-color: #ffed8d;
        font-size: 3vh;
        line-height: 9vh;
        margin-bottom: 3vw;
        text-align: center;
        cursor: pointer;
        &.countdown{
            background-color: #f26d7d;
            color: #ffffff;
            
            .tap_int{
                margin-top: -6vh;
                @include for-mobile-portrait-screen{
                   line-height: 10vw;
                    margin-top: -5vw;
                }
               
            }
        }
        .tap_int{
            font-family: 'MontserratRegular';
            font-size: 2vh;
            margin-top: 0;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
                line-height: 14vw;
                margin-top: 0;
            }
            @include for-tab-portrait-screen{
                font-size: 3vw;
                line-height: 10vw;
            }
        }
        @include for-mobile-portrait-screen{
            font-size: 5vw;
            line-height: 14vw;
            // margin-top: -10vw;
        }
        @include for-tab-portrait-screen{
            font-size: 4vw;
            line-height: 12vw;
        }

    }
    ::-webkit-scrollbar {
        display: none;
      }
}

.remove_confirm{
    position: fixed;
    top:50%;
    left: 50%;
    width: 80vw;
    max-width: 500px;
    transform: translate(-50%, -50%);
    background-color: #000000;
    border-radius: 20px;
    padding: 5vh 2vh;
    @include for-mobile-portrait-screen{
        padding: 10vw 3vw;
    }
    .close{
        position: absolute;
        width: 3vh;
        height: 3vh;
        padding: 3vh;
        top: -3vh;
        right: -3vh;
        cursor: pointer;
        @include for-mobile-portrait-screen{
          width: 5vw;
          height: 5vw;
          padding: 5vw;
          top: -5vw;
          right: -5vw;
      }
        border-radius: 50%;
        overflow: hidden;
        background-color: #684d94;
        display: flex;
     
        img{
            position: relative;
            width: 100%;
       
        }
    }
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .profile_img{
            position: relative;
            width: 25vh;
            @include for-mobile-portrait-screen{
                width: 35vw;
                margin-bottom: 5vw;
            }
            img{
                position: relative;
                width: 100%;
            }
            .profile{
                display: flex;
                flex-direction: column;
            }
            margin-bottom: 3vh;
        }
        .picked_head{
            color: #ffffff;
            font-family: 'MontserratRegular';
            font-size: 3vh;
            text-align: center;
            margin: 1vh 0;
            width: 80%;
            @include for-mobile-portrait-screen{
                font-size: 5vw;
                margin: 3vw 0;
            }
        }
        #removeConfirm{
            position: relative;
            width: 70%;
            margin: auto;
            border-radius: 15px;
            font-family: 'MontserratBold';
            color: #000000;
            background-color: #ffed8d;
            font-size: 3vh;
            padding: 20px;
            margin: 3vh 0 0 0;
            text-align: center;
            cursor: pointer;
            @include for-mobile-portrait-screen{
                font-size: 5vw;
                margin: 6vw 0 0 0;
            }
        }
    }
}