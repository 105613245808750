@import '../../../styles/global.scss';
.reports_mini{
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
    &.darkMode{
        background-color: #282828;
        border: 1px solid #434343;
        .report_title,
        .info_sub{
            color: #ffffff !important;
        }
        .info_sec{
            color: #ffffff !important;
        }
    }
    border-radius: 10px;
    width: 100%;
    height: 100px;
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.mini{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        width: calc(100% - 20px);
        padding-left: 10px;
        .left{
            .cover_photo{
                width: 50px;
                height: 50px;
                border-radius: 5px;
                img{
                    width: 100%;
                
                }
            }
            .report_mini_info{
                margin-left: 10px;
                .report_title{
                    font-size: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 50%;
                }
                .info_sub{
                    font-size: 12px;
                }
                 .info_footer{
                    display: none;
                }
            }
        }
        .right{
          
            margin-right: 10px;
            width: 100%;
            justify-content: space-between;
        }
    }
    .left{
        display: flex;
        flex-direction: row;
        .cover_photo{
            position: relative;
            width: 100px;
            height: 100px;
            background-color: #f1f1f1;
            border-radius: 10px;
            overflow: hidden;
            img{
                position: absolute;
                top:50%;
                left: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translate(-50%, -50%);
            }
        }
        .report_mini_info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;
            .report_title{
                font-family: 'MontserratBold';
                color: #000000;
                font-size: 20px;
                margin-bottom: 5px;
                position: relative;
                // max-width: 20vw;
                max-width: calc(33vw - 100px);
                @include dashboard-landscape-limit{
                    max-width: 20vw;
                }
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .info_sub{
                font-family: 'MontserratLight';
                color: #000000;
                font-size: 14px;
                margin-bottom: 5px;
            }
            .info_footer{
                display: flex;
                flex-direction: row;
                .teams{
                    display: flex;
                    flex-direction: row;
                    // margin-right: 10px;
                    .mini_info{
                        margin-right: 10px;
                    }
                }
                .mini_info{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: #dcdcdc;
                    padding: 5px 10px;
                    border-radius: 5px;
                  
                    .icon{
                        position: relative;
                        width: 15px;
                        display: flex;
                        img{
                            position: relative;
                            width: 100%;
                        }
                        margin-right: 5px;
                    }
                    .label{
                        font-family: 'MontserratRegular';
                        color: #000000;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .right{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        .info_sec{
            font-family: 'MontserratLight';
            color: #000000;
            font-size: 14px;
            margin-right: 20px;
            @include dashboard-landscape-limit{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            span.bold{
                font-family: 'MontserratRegular';
                font-size: 16px;
            }
        }
        .buttons{
            display: flex;
            flex-direction: row; 
            margin: 0 10px 0 0;
            .button{
                margin-left: 10px;
                .icon{
                    opacity: 0.75;
                    transform: scale(0.85);
                }
            }
        }
      
        
    }
}