@import '../global.scss';

.profile_crop_area{
    position: fixed;
    top:0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 10;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.active{
        display: flex;
    }
    .ReactCrop{
        position: relative;
        width: 80%;
        max-width: 800px;
       max-height: 60vh;
       margin-bottom: 30px;
    }
    .ReactCrop__crop-mask{
        // display: none;
    }
}
.profile_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .middle_area{
            
            .head{
                .title{
                    color: #ffffff !important;
                }
                .right{
                    a{
                        color: #ffffff !important;
                        &.active{
                            color: #684d94 !important;
                        }
                    }
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                    }
                    ::placeholder{
                        color: #ffffff;
                    }
                }
            }
            .middle_content{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
                .field{
                    border-bottom: 1px solid #ffffff !important;
                    input, select, .Dropdown-placeholder{
                        color: #ffffff !important;
                    }
                    ::placeholder{
                        color: #ffffff !important;
                    }
                }
                .Dropdown-control,
                .rs-input-group.rs-input-group-inside{
                    background-color: transparent,;
                }
            }
        }
    }
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
            width: calc(100% - 250px);
          }
          @include dashboard-landscape-limit{
            width: calc(100% - 350px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 70px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .head{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .left{
                    .title{
                        font-family: 'MontserratBold';
                        color: #000000;
                        font-size: 30px;
                    }
                }
                .right{
                    display: flex;
                    flex-direction: row;
                    a{
                        font-family: 'MontserratBold';
                        color: #93949b;
                        font-size: 14px;
                        text-underline-offset: 5px;
                        &:nth-child(2){
                            margin-left: 30px;
                        }
                        &.active{
                            color:#684d94;
                        }
                    }
                }
            }
            .profile_sec{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 90%;
                max-width: 450px;
                justify-content: center;
                margin: auto;
                .profile_photo_sec{
                    position: relative;
                    width: 200px;
                    height: 200px;
                    .photo{
                        position: relative;
                        width: 200px;
                        height: 200px;
                        &.mini{
                            width: 100px;
                            height: 100px;
                            padding: 50px;
                        }
                        background-color: #684d94;
                        border-radius: 50%;
                        overflow: hidden;
                        img{
                            position: relative;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    #profile_img_trigger{
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-35%, -50%);
                        z-index: -1;
                    }
                 
                    .camera_trigger{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #ffffff;
                        box-shadow: 1px 1px 13px #d6d6d6;
                        cursor: pointer;
                        &:hover{
                            background-color: #ffed8d;
                        }
                        img{
                            position: relative;
                            width: 100%;
                        }
                    }
                }
                .field{
                    .rs-input-group-addon{
                        display: none;
                    }
                    .Dropdown-control{
                        padding: 0;
                    }
                    .Dropdown-root{
                        width: 100%;
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 16px;
                        line-height: 40px;
                    }
                    .rs-picker{
                        input{
                            padding: 0;
                        }
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: transparent;
                    border-bottom: 1px solid #d4d4d4;
                    margin-bottom: 15px;
                    position: relative;
                    width: 100%;
                    div{
                        border: 0;
                        outline: none;
                    }
                    .icon{
                        position: relative;
                        height: 25px;
                        img, svg{
                            position: relative;
                            height: 100%;
                        }
                        margin-right: 10px;
                    }
                    input{
                        width: 100%;
                        background-color: transparent;
                        outline: none;
                        border: 0;
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 16px;
                        line-height: 40px;
                    }
                    ::placeholder{
                        color: #000000;
                    }
                }
                .inline_fields{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .field{
                        width: 45%;
                    }
                }
                .button{
                    margin-top: 10px;
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
    }
}