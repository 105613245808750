.tutorial{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,0.75);
   
    .cover{
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.75);
        z-index: 1001;
    }
    .mascot{
        position: absolute;
        height: 400px;//min(30vw, 40vh);
        bottom:0;
        transform: translateY(10%);
        right: 10%;
        opacity: 0.0001;
        z-index: 1001;
        &.active{
            opacity: 1;
        }
        img{
            position: relative;
            height: 100%;
        }
    }
    &.darkMode{
        .tutorial_popup{
            background-color: #ffffff;
            .arrow-left-top{
                border-right:30px solid #ffffff; 
            }
            .arrow-right-top{
                border-left:30px solid #ffffff; 
            }
            .arrow-top-left{
                border-bottom:30px solid #ffffff; 
            }
            .arrow-top-right{
                border-bottom:30px solid #ffffff; 
            }
            .title, .desc{
                color: #000000;
            }
            .tutorial_footer{
                .footer_nav{
                    color: #000000;
                }
            }
        }
        
    }
    .tutorial_popup{
        position: absolute;
        top:-100%;
        left: -100%;
        left: 0;
        width: 500px;
        background-color: #000000;
        border-radius: 20px;
        padding: 20px 20px 30px 20px;
        z-index: 10001;
        .arrow-left-top{
            position: absolute;
            top: 8%;
            left: -30px;
            width: 0; 
            height: 0; 
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent; 
            
            border-right:30px solid #000000; 
        }
        .arrow-right-top{
            position: absolute;
            top: 8%;
            right: -30px;
            width: 0; 
            height: 0; 
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent; 
            
            border-left:30px solid #000000; 
        }
        .arrow-top-left{
            position: absolute;
            left: 5%;
            top: -30px;
            width: 0; 
            height: 0; 
            border-left: 25px solid transparent;
            border-right: 25px solid transparent; 
            
            border-bottom:30px solid #000000; 
        }
        .arrow-top-right{
            position: absolute;
            right: 5%;
            top: -30px;
            width: 0; 
            height: 0; 
            border-left: 25px solid transparent;
            border-right: 25px solid transparent; 
            
            border-bottom:30px solid #000000; 
        }
        .title{
            font-family: 'MontserratBold';
            font-size: 25px;
            color: #ffffff;
            margin-bottom: 5px;
        }
        .desc{
            font-family: 'MontserratLight';
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
        }
        .tutorial_footer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            align-items:center;
            .footer_nav{
                font-family: 'MontserratLight';
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
            }
            .right{
                display: flex;
                flex-direction: row;
                .button:nth-child(2){
                    margin: 0 10px;
                }
            }
        }
    }
}

*.bringToTop{
    position: relative;
    z-index: 10000;
    pointer-events: none;
    opacity: 1 !important;
}