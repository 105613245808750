@import './global.scss';
.thoughts_sec{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    align-items: center;
    // max-width: 550px;
   

    margin: auto;
    .role{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #dadada;
        border-radius: 20px;
        justify-content: flex-start;
        padding: 1vh 4vh;
        max-width: 500px;
        margin: auto;
        @include for-mobile-portrait-screen{
            padding: 2vw 4vw;
        }
   
        .role_icon{
            position: relative;
            width: 6vh;
            @include for-mobile-portrait-screen{
                width: 10vw;
            }
            margin-right: 10%;
            img{
                position: relative;
                width: 100%;
            }
        }
        .label{
            font-family: 'MontserratBold';
            font-size: 2.5vh;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
            }
            position: relative;
        }
        margin-bottom: 2vw;
    }
    .thoughts{
        max-width: 550px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        // justify-content: center;
  
        max-height: 60vh;
        overflow: auto;
        @include for-tab-portrait-screen{
            max-height: 60vh;
        }
        .thought,.students{
            .student{
                
                // background-color: rgba(255, 255, 255, 0.65);
                position: relative;
                width: calc(100% - 0vh);
                .profile{
                    width: 80%;
                }
                max-width: 400px;
                margin-bottom: 10px;
                // border-bottom: 1px solid #dadada;
                // padding: 2vh 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                // margin: auto;
                // border: 1px solid #dadada;
                padding: 0vh 0vh;
                border-radius: 10px;
                align-items: center;
                @include for-mobile-portrait-screen{
                    padding: 0vw 0vw;
                    width: calc(100% - 0vw);
                    max-width: none;
                }
                .close{
                    position: relative;
                    width: 1.5vh;
                    height: 1.5vh;
                    padding: 1.5vh;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: #f26d7d;
                    display: flex;
                    cursor: pointer;
                    @include for-mobile-portrait-screen{
                        width: 2.5vw;
                        height: 2.5vw;
                        padding: 2.5vw;
                    }
                    img{
                        position: relative;
                        width: 100%;
                    }
                }
                &:last-child{
                    // border: none;
                    margin-bottom: 0;
                }
            }
            &.disabled{
                pointer-events: none;
            }
            &.purple-minimal{
                width: 70%;
                margin: auto;
                margin-bottom: 10px;
                .profile{
                    &:hover, &.active{
                        background-color: #000000 !important;
                    }
                }
                .list-group-item{
                    .card-title{
                        position: relative;
                        .info_sec{
                            position: absolute;
                            left: 2%;
                            background-color: #ffed8d;
                            font-family: 'MontserratBold';
                            font-size: 1.5vh;
                            width: 3vh;
                            height: 3vh;
                            color: #000000;
                            border-radius: 50%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            @include for-mobile-portrait-screen{
                                font-size: 2.5vw;
                                width: 4.5vw;
                                height: 4.5vw;
                              
                            }
                        }
                        background-color: #684d94;
                        color: #ffffff;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        font-size: 1.25vh;
                        padding: 1vh 1vh;
                        @include for-mobile-portrait-screen{
                            padding: 1.5vw 3vw;
                            font-size: 1.75vw;
                        }
                      
                    }
                }
            }
            margin-bottom: 0vh;
            @include for-mobile-portrait-screen{
                margin-bottom: 0.5vw;
            }
            .list-group-item{
                
                border-radius: 5vh;
                @include for-mobile-portrait-screen{
                    border-radius: 10vw;
                }
                background-color: #000000;
                .card-title {
                    cursor: pointer;
                    background-color: #ffed8d;
                    margin: 0;
                    font-family: 'MontserratRegular';
                    h2{
                        font-size: revert;
                        line-height: revert;
                    }
                    font-size: 1.5vh;
                    padding: 2.5vh 2vh;
                    @include for-mobile-portrait-screen{
                        font-size: 2vw;
                        padding: 3.5vw 3vw;
                        border-radius: 30px;
                    }
                    @include for-tab-portrait-screen{
                        padding: 2.75vw 2.5vw;
                        font-size: 1.65vw;
                    }
                    border-radius: 60px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border: 2px solid #ffffff;
                    align-items: center;
                    .profiles{
                        display: flex;
                        flex-direction: row;
                        .profile{
                            margin-right:0 !important;
                            cursor: pointer;
                        }
                    }
                    *{
                        margin: 0;
                    }
                  }
                  .card-collapse {
                    overflow: hidden;
                    transition: height 0.3s ease-out;
                    background-color: #000000;
                    border-radius: 20px;
                    .card-body{
                        color: #ffffff;
                        padding: 1vh 2vh;
                        @include for-mobile-portrait-screen{
                            padding: 2vw 4vw;
                        }
                        .profile{
                            background-color: #000000;
                           
                            margin-top: 1vh;
                           
                            @include for-mobile-portrait-screen{
                                margin-top: 2vw;
                            }
                          
                            &:hover, &.active{
                                background-color: #684d94;
                            }
                        }
                    }
                  }
            }
        }
        
       
    }
    ::-webkit-scrollbar {
        display: none;
      }
    .thoughts_navs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
        // left: -10vw;
        position: relative;
        margin: 1.5vh 0 2vh 0;
        @include for-mobile-portrait-screen{
            margin: 3vw 0 3vw 0;
        }
        .thoughts_nav{
            background-color: #684d94;
            display: flex;
            flex-direction: row;
            padding: 3vh 5vh;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @include for-mobile-portrait-screen{
                padding: 4vw 6vw;
            }
            @include for-tab-portrait-screen{
                padding: 3vw 4vw;
            }
            &.disabled{
                cursor: none;
                pointer-events: none;
                opacity: 0.5;
            }
            &.next{
                flex-direction: row-reverse;
                border-radius: 2vh 0 0 2vh;
                @include for-mobile-portrait-screen{
                    border-radius: 2vw 0 0 2vw;
                }
            }
            &.prev{
                border-radius: 0 2vh 2vh 0;
                @include for-mobile-portrait-screen{
                    border-radius: 0 2vw 2vw 0;
                }
            }
            .icon{
                width: 3vh;
                height: 3vh;
                @include for-mobile-portrait-screen{
                    width: 5vw;
                    height: 5vw;
                }
                // margin: 0 3vw;
                img{
                    position: relative;
                    width: 100%;
                }
            }
            .label{
                position: relative;
                font-family: 'MontserratBold';
                font-size: 3vh;
                color: #ffffff;
                margin: 0 2vh;
                @include for-mobile-portrait-screen{
                    margin: 0 2vw;
                    font-size: 3vw;
                }
            }
        }
    }
    #replay_qn{
        font-family: 'MontserratBold';
        font-size: 2.25vh;
        cursor: pointer;
        @include for-mobile-portrait-screen{
            font-size: 3.5vw;
            text-decoration: underline;
        }
        @include for-tab-portrait-screen{
            font-size: 3vw;
        }
        &:hover{
            text-decoration: underline;
        }
    }
    #nextQn, #endSession{
        position: relative;
        width: 60%;
        max-width: 400px;
        margin: auto;
        border-radius: 15px;
        font-family: 'MontserratBold';
        color: #ffffff;
        background-color: #7cc576;
        font-size: 3vh;
        line-height: 9vh;
        text-align: center;
        cursor: pointer;
        @include for-mobile-portrait-screen{
            font-size: 5vw;
            line-height: 16vw;
            max-width: none;
        }
        @include for-tab-portrait-screen{
            font-size: 4vw;
            line-height: 12vw;
            max-width: none;
        }
    }
}

.thoughts_detail{
    position: fixed;
    top:50%;
    left: 50%;
    width: 80vw;
    max-width: 500px;
    transform: translate(-50%, -50%);
    background-color: #000000;
    border-radius: 20px;
    padding: 6vh 3vh;
    @include for-mobile-portrait-screen{
        padding: 10vw 3vw;
    }
    .close{
        position: absolute;
        width: 3vh;
        height: 3vh;
        padding: 3vh;
        top: -3vh;
        right: -3vh;
        cursor: pointer;
        @include for-mobile-portrait-screen{
          width: 5vw;
          height: 5vw;
          padding: 5vw;
          top: -5vw;
          right: -5vw;
      }
       
        border-radius: 50%;
        overflow: hidden;
        background-color: #684d94;
        display: flex;
     
        img{
            position: relative;
            width: 100%;
            // padding: 5vw;
        }
    }
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
        .profile_img{
            position: relative;
            width: 22vh;
            @include for-mobile-portrait-screen{
                width: 35vw;
                margin-bottom: 5vw;
            }
            .profile{
                display: flex;
                flex-direction: column;
            }
            .img{
                margin-bottom: 3vh;
                @include for-mobile-portrait-screen{
                    margin-bottom: 4vw;
                }
            }
            img{
                position: relative;
                width: 100%;
              
            }
            margin-bottom: 3vh;
        }
        .profile_info{
            .name{
                font-family: 'MontserratBold';
                color: #ffffff;
                
                text-align: center;
                font-size: 5vh;
                margin-bottom: 1vh;
                @include for-mobile-portrait-screen{
                    font-size: 5vw;
                    margin-bottom: 1vw;
                }
            }
            .response{
                color: #ffed8d;
                font-family: 'MontserratBold';
                font-size: 3vh;
                width: 100%;
                text-align: center;
                @include for-mobile-portrait-screen{
                    font-size: 4.5vw;
                }
            }
        }
        .why_head{
            color: #ffffff;
            font-family: 'MontserratRegular';
     
            text-align: center;
            font-size: 3vh;
            margin: 3vh 0;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
                margin: 3vw 0;
            }
        }
        .explanation{
            background-color: #ffffff;
        
            color: #000000;
            font-family: 'MontserratRegular';
            padding: 3vh;
            font-size: 3.5vh;
            max-height: 20vh;
            width: 80%;
            border-radius: 10px;
            text-align: center;
           
            overflow-y: auto;
            @include for-mobile-portrait-screen{
                padding: 4vw;
                font-size: 3.5vw;
                max-height: 20vh;
                padding: 3vh;
            }
        }
        #pickToShare{
            position: relative;
            width: 70%;
            margin: auto;
            border-radius: 15px;
            font-family: 'MontserratBold';
            color: #000000;
            background-color: #ffed8d;
            font-size: 3vh;
            padding: 20px;
            margin: 6vh 0 0 0;
            text-align: center;
            cursor: pointer;
            @include for-mobile-portrait-screen{
                font-size: 5vw;
                padding: 20px;
                margin: 6vw 0 0 0;
            }
        }
        .picked_head{
            font-family: 'MontserratBold';
            color: #ffffff;
            text-align: center;
            font-size: 4vh;
            margin: 5vh auto 2vh auto;
            @include for-mobile-portrait-screen{
                font-size: 5vw;
                margin: 10vw auto 5vw auto;
            }
        }
        .picked_info{
            font-family: 'MontserratRegular';
            color: #ffffff;
            text-align: center;
            font-size: 3vh;
            width: 80%;
            @include for-mobile-portrait-screen{
                font-size: 4.5vw;
            }
        }
        ::-webkit-scrollbar {
            display: none;
          }
    
    }
}