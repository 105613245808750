@import './global.scss';
.bg_cover{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
    img{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
header.landscape_only, footer.landscape_only{
    @include for-mobile-portrait-screen{
        display: none !important;
    }
   
}
.result_container{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:45%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include for-mobile-portrait-screen{
        display: none;
    }
    // width: 90%;
    // max-width: 1000px;
    .title{
        color: #000000;
        font-family: 'MontserratBold';
        font-size: 4vh;
        text-align: center;
        margin-bottom: 5vh;
    }
    .result_charts{
        display: flex;
        flex-direction: row;
        justify-content: center;
        
    }
    .chart, .chart2{
        &.chart2{
            position: absolute;
            height: max(20%, min(14vh, 12vw));
            right: -14vw;
            bottom: 0;
            .chart_progress{
                position: relative;
                flex-direction: column;
                justify-content: center;
                .head{
                    color: #000000;
                    font-family: 'MontserratRegular';
                    font-size: 1.2vw;
                    color: #ffffff;
                    margin-bottom: 0.5vw;
                }
                .txt{
                    color: #ffffff;
                    font-size: 2vw;
                }
                background-color: #6d6d6d;
                
            }
            background-color: transparent;
        }
        position: relative;
        width: 10vw;
        max-width: 200px;
        height: min(45vh, 40vw);//max(45vh, 30vw);
        background-color: #684d94;

        margin-right: 10px;
        border-radius: 20px;
        overflow: hidden;
   
        .polls{
            position: absolute;
            top:0;
            width: 70%;
            left: 50%;
            transform: translateX(-50%);
            // width: 100%;
            max-height: 25vh;
            overflow-y: auto;
            padding: 10px 0;
            .profile{
                margin: 5px 0;
            }
        }
        ::-webkit-scrollbar {
            display: none;
          }
        /* .chart_bg{
            background-color: #684d94;
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 70%;
        } */
        .chart_progress{
            position: absolute;
            bottom: 0;//min(5vw, 200px);
            left: 0;
            width: 100%;
            min-height: max(20%, min(14vh, 12vw));//max(20%, 14vh);
            border-radius: 20px;
            background-color: #ffed8d;
            display: flex;
            flex-direction: row;
            align-items: center;
            
            .txt{
                position: relative;
                bottom: 3%;
                color: #000000;
                font-family: 'MontserratBold';
                font-size: 1.2vw;
                text-align: center;
                width: 80%;
                margin: 0 10%;
            }
        }
       
    }
}