@import './global.scss';

.code_sec{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    margin: auto;
    .role{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #dadada;
        border-radius: 20px;
        justify-content: flex-start;
        padding: 1vh 4vh;
        max-width: 500px;
        margin: auto;
     
        @include for-mobile-portrait-screen{
            padding: 2vw 4vw;
        }
        .role_icon{
            position: relative;
            width: 6vh;
            margin-right: 10%;
            @include for-mobile-portrait-screen{
                width: 10vw;
            }
            img{
                position: relative;
                width: 100%;
            }
        }
        .label{
            font-family: 'MontserratBold';
            // font-size: 25px;
            position: relative;
            font-size: 2.5vh;
            @include for-mobile-portrait-screen{
                font-size: 4vw;
            }
        }
    }
    .code_title{
        font-family: 'MontserratRegular';
        font-size: 3vh;
        width: 80%;
        
        margin: 3.5vh auto;
        text-align: center;
        color: #000000;
        position: relative;
        @include for-mobile-portrait-screen{
            font-size: 4.5vw;
            margin: 5vw auto;

        }
    }
    .code_field{
        position: relative;
        width: calc(100% - 4vh);
        max-width: 500px;
        background-color: #ffffff;
        padding: 0 2vh;
        border:2px solid #dadada;
        border-radius: 15px;
        overflow: hidden;
       
        margin: auto;
        margin-bottom: 4vh;
        @include for-mobile-portrait-screen{
            padding: 0 2vw;
            width: calc(100% - 4vw);
            margin-bottom: 4vw;
        }
        input{
            background-color: transparent;
            border: none;
            outline: none;
            font-family: 'MontserratRegular';
            font-size: 3.5vh;
            line-height: 9vh;
            text-align: center;
            position: relative;
            width: 100%;
            @include for-mobile-portrait-screen{
                font-size: 4.5vw;
                line-height: 15vw;
            }
        }
    }
    .invalid_sec{
        font-family: 'MontserratRegular';
        font-size: 3vh;
        color: #ff0000;
        text-align: center;
        margin-top: 2vh;
        @include for-mobile-portrait-screen{
            font-size: 4vw;
            margin-top: 4vw;
        }
    }
    #codeEnter{
        position: relative;
        width: 70%;
        max-width: 350px;
        margin: auto;
        border-radius: 15px;
        font-family: 'MontserratBold';
        color: #000000;
        background-color: #ffed8d;
        font-size: 3.5vh;
        line-height: 10vh;
        text-align: center;
        cursor: pointer;

        &.disabled{
            opacity: 0.5;
            pointer-events: none;
        }
        &:hover{
            background-color: #000000;
            color: #ffffff;
        }
       
        @include for-mobile-portrait-screen{
            font-size: 5vw;
            line-height: 16vw;
        }

    }
}