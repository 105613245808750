.thoughtfully_mini{
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
    &.darkMode{
        background-color: #282828;
        border: 1px solid #434343;
        .thoghtfully_title,
        .info_sub{
            color: #ffffff !important;
        }
    }
    border-radius: 10px;
    width: 100%;
    height: 100px;
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.mini{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        width: calc(100% - 20px);
        padding-left: 10px;
        .left{
           
            .cover_photo{
                width: 50px;
                height: 50px;
                border-radius: 5px;
                img{
                    width: 100%;
                }
            }
            .thoghtfully_mini_info{
                margin-left: 10px;
                max-width: calc(100% - 50px);
                .thoghtfully_title{
                    font-size: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // width: 40%;
                    width: 125px;
                }
                .info_sub{
                    font-size: 12px;
                }
                 .info_footer{
                    display: none;
                }
            }
        }
        .right{
            .live_btn{
                font-size: 12px;
                border-radius: 5px;
                padding: 7.5px 15px;
            }
            .thoghtfully_mini_trigger{
                padding: 0 10px 0 10px;
                span{
                    width: 5px;
                    height: 5px;
                    &:nth-child(2){
                        margin: 2px 0;
                    }
                }
            }
            width: 100%;
            justify-content: space-between;
        }
    }
    .left{
        display: flex;
        flex-direction: row;
        max-width: 100%;
        overflow: hidden;
        .cover_photo{
            position: relative;
            width: 100px;
            height: 100px;
            background-color: #f1f1f1;
            border-radius: 10px;
            overflow: hidden;
            img{
                position: absolute;
                top:50%;
                left: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translate(-50%, -50%);
            }
        }
        .thoghtfully_mini_info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;
            max-width: calc(100% - 100px);
            overflow: hidden;
            .thoghtfully_title{
                font-family: 'MontserratBold';
                color: #000000;
                font-size: 20px;
                margin-bottom: 5px;
                position: relative;
                max-width: 25vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .info_sub{
                font-family: 'MontserratLight';
                color: #000000;
                font-size: 14px;
                margin-bottom: 5px;
            }
            .info_footer{
                display: flex;
                flex-direction: row;
                .teams{
                    display: flex;
                    flex-direction: row;
                    // margin-right: 10px;
                    .mini_info{
                        margin-right: 10px;
                    }
                }
                .mini_info{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: #dcdcdc;
                    padding: 5px 10px;
                    border-radius: 5px;
                  
                    .icon{
                        position: relative;
                        width: 15px;
                        display: flex;
                        img{
                            position: relative;
                            width: 100%;
                        }
                        margin-right: 5px;
                    }
                    .label{
                        font-family: 'MontserratRegular';
                        color: #000000;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .right{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        .live_btn{
            font-family: 'MontserratBold';
            background-color: #d4f0b2;
            color: #80ab4a;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            &:hover{
                background-color: #ffed8d;
                color: #000000;
            }
           
        }
        .thoghtfully_mini_trigger{
            display: flex;
            flex-direction: column;
            padding: 0 20px 0 20px;
            cursor: pointer;
            span{
                width: 7px;
                height: 7px;
                border-radius: 2px;
                background-color: #bababa;
                &:nth-child(2){
                    margin: 4px 0;
                }
            }
        }
        
    }
}