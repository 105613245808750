@import '../global.scss';

.shared_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .middle_area{
            .head{
                .title{
                    color: #ffffff !important;
                }
                .right{
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                    }
                    ::placeholder{
                        color: #ffffff !important;
                    }
                }
            }
            .middle_content{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
            }
        }
    }
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
            width: calc(100% - 250px);
          }
          @include dashboard-landscape-limit{
            width: calc(100% - 350px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // @include dashboard-landscape-limit{
            //     flex-direction: column;
            // }
            .left{
                .title{
                    font-family: 'MontserratBold';
                    color: #000000;
                    font-size: 30px;
                }
            }
            .right{
                display: flex;
                flex-direction: row;
                // @include dashboard-landscape-limit{
                //     justify-content: space-between;
                //     margin: 10px 0;
                // }
                input{
                    position: relative;
                    background-color: #ffffff;
                    border: none;
                    padding: 10px 20px;
                    font-family: 'MontserratLight';
                    border-radius: 30px;
                    outline: none;
                    font-size: 16px;
               
                    width: 200px;
                    box-shadow: 1px 1px 13px #d6d6d6;

                }
                .button{
                    margin-left: 20px;
                    position: relative;
                    .label{
                        width: 170px;
                    }
                }
            }
        }
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 100px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            .thoughtfully_mini{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
        
    }
    .right_area{
        position: relative;
        width: 210px;
        padding: 15px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px #d6d6d6;
        border-radius: 10px;
        overflow-y: auto;
   
    }
    ::-webkit-scrollbar {
        display: none;
    }
}
::placeholder{
    color: #000000 !important;
}