@import '../global.scss';

body{
  overflow-y: hidden;
}

.preview_sec{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: white;
}
.preview_controls{
  position: absolute;
  bottom: 0;
  width: calc(100% - 100px);
  padding: 20px 50px;
  background-color: rgba(0,0,0,0.85);
  z-index: 101;
  transform: translateY(100%);
  z-index: 1000;
  transition: transform cubic-bezier(.72, 0, .28, 1) 0.5s 0s;
  &.active{
    transform: translateY(0);
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.thumbnail_crop_area{
  position: fixed;
  top:0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 10;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.active{
      display: flex;
  }
  .ReactCrop{
      position: relative;
      // width: 80%;
      // max-width: 800px;
     max-height: 60vh;
     margin-bottom: 30px;
  }
  .ReactCrop__crop-mask{
      // display: none;
  }
}
.thoughtfully_view_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
      .middle_area{
          .head{
              .title{
                  color: #ffffff !important;
              }
              .right{
                  input{
                      background-color: #454545;
                      color: #ffffff;
                      box-shadow: 1px 1px 13px #454545;
                  }
                  ::placeholder{
                      color: #ffffff !important;
                  }
                  
              }
          }
          .middle_content{
              background-color: #181818;
              box-shadow: 1px 1px 13px #262626;
              .right{
                .activity_view_head{
                  .title{
                    color: #ffffff !important;
                  }
                }
              }
              .left{
                .field{
                  ::placeholder{
                    color: #ffffff !important;
                }
                  &.disabled{
                    .react-tags{
                      background-color: transparent !important;
                    }
                  }
                  .react-tags{
                    background-color: #454545 !important;
                    border: none !important;
                  }
                  input, textarea{
                    background-color: #454545 !important;
                    color: #ffffff;
                    border: none !important;
                    &:disabled{
                      background-color: transparent !important;
                    }
                    // box-shadow: 1px 1px 13px #454545;
                }

                }
                
              
              .react-tags__suggestions{
                background-color: #454545 !important;
                color: #ffffff !important;
                box-shadow: 1px 1px 13px #262626;
                ul{
                  li{
                    &:hover{
                      color: #000000 !important;
                    }
                  }
              }
            }
              ::placeholder{
                  color: #ffffff;
              }
              }
              .time_left{
                background-color: #282828 !important;
                border: 1px solid #434343 !important;
                .title{
                  color: #ffffff !important;
                }
                .dec, .inc{
                  background-color: #ffffff !important;
                  color: #000000 !important;
                  &:hover{
                    background-color: #684d94 !important;
                    color: #ffffff !important;
                  }
                }
              }
          }
         /*  .activity_photo_sec{
            .photo{
              background-color: #282828 !important;
            }
          } */
      }
      .extra_time_picker{
        .title, .extras{
          color: #ffffff !important;
        }
       
      }
  }
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
          width: calc(100% - 250px);
        }
        @include dashboard-landscape-limit{
          width: calc(100% - 350px);
      }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 70px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &>.right{
              width: calc(100% - 280px);
              @include dashboard-landscape-limit{
                  width: calc(100% - 230px);
              }
            }
            .left{
              .sec1{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100% - 250px);
              }
              .bottom{
                position: relative;
                .field{
                  ::placeholder{
                    color: #ffffff;
                }
                  &.inline{
                    .button{
                      position: absolute;
                      top:10px;
                      right: 10px;
                      // transform: translateY(-50%);
                      .label{
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
                .activity_edit_trigger{
                  cursor: pointer;
                  position: absolute;
                  z-index: 10;
                  top: 15px;
                  right: 0px;
                  display: flex;
                  flex-direction: column;
                  padding: 0 10px;
                  span{
                    width: 5px;
                    height: 5px;
                    border-radius: 1px;
                    background-color: #000000;
                    &:nth-child(2){
                        margin: 2px 0;
                    }
                }
                }
                position: relative;
                // width: 250px;
                // width: 80%;

                .activity_photo_sec{
                  position: relative;
                  width: 250px;
                  height: 250px;
                  @include dashboard-landscape-limit{
                      width: 200px;
                      height: 200px;
                  }
                  .photo{
                      position: relative;
                      width: 250px;
                      height: 250px;
                
                      @include dashboard-landscape-limit{
                          width: 200px;
                          height: 200px;
                      }
                      background-color: #f4f4f4;
                      border-radius: 10px;
                      overflow: hidden;
                      img{
                        position: relative;
                        width: 250px;
                      height: 250px;
                      @include dashboard-landscape-limit{
                          width: 200px;
                          height: 200px;
                      }
                      object-fit: cover;
                      }
                  }
                  .camera_trigger{
                      position: absolute;
                      bottom: 10px;
                      right: 10px;
                      width: 30px;
                      height: 30px;
                      padding: 5px;
                      border-radius: 50%;
                      overflow: hidden;
                      background-color: #ffffff;
                      box-shadow: 1px 1px 13px #d6d6d6;
                      cursor: pointer;
                      &:hover{
                          background-color: #ffed8d;
                      }
                      img{
                          position: relative;
                          width: 100%;
                      }
                  }
                  #activity_img_trigger{
                    position: absolute;
                    top:50%;
                    left: 50%;
                    transform: translate(-35%, -50%);
                    z-index: -1;
                }
              }
                .field{
                  ::placeholder{
                    color: #ffffff;
                }
                    position: relative;
                    width: 250px;
                    @include dashboard-landscape-limit{
                      width: 200px;
                  }
                    margin-top: 10px;
                    &>.label{
                      font-size: 14px;
                      color: #000000;
                      font-family: 'MontserratLight';
                      margin-bottom: 10px;
                    }
                    &.disabled_underline{
                      border-bottom: 1px solid #c4c4c4;

                    }
                    &.toggle{
                      .toggle_container{
                        position: relative;
                        &>div{
                          width: 100% !important;
                        }
                       
                        .toggle_labels{
                          display: flex;
                          flex-direction: row;
                          position: absolute;
                          top:0;
                          left: 0;
                          z-index: 10;
                          top:50%;
                          transform: translateY(-50%);
                          pointer-events: none;
                          .label{
                            width: 50%;
                            font-family: 'MontserratBold';
                            font-size: 14px;
                            text-align: center;
                            color: #ffffff;
                          }
                        }
                      }
                    
                    }
                    &.disabled{
                      border-radius: 0;
                      pointer-events: none;
                 
                      input, textarea{
                        border: none;
                      }
                      .react-tags{
                        border: none;
                        padding: 0;
                      }
                      .react-tags__search{
                        display: none;
                        border: none;;
                      }
                    }
                    input, textarea{
                      &#thoughtfully_name{
                       
                       
                        
                        // width: 100%;
                        &:disabled{
                          white-space: pre-wrap;
                          text-overflow: ellipsis;
                          max-width: 80%;
                          overflow: hidden;
                          border-radius: 0;
                          font-family: 'MontserratBold';
                          padding: 10px 0px;
                        }
                      }
                      &:disabled{
                        border: none;
                        padding: 0px 0px;
                        // white-space: pre-wrap;
                        // text-overflow: ellipsis;
                        // max-width: 80%;
                        // overflow: hidden;
                      }
                        position: relative;
                        background-color: #ffffff;
                        border: 1px solid #c4c4c4;
                        padding: 10px 20px;
                        font-family: 'MontserratLight';
                        border-radius: 10px;
                        outline: none;
                        font-size: 14px;
                   
                        width: calc(100% - 42px);
    
                    }
                }
                .left_buttons{
                  margin: 20px 0;
                  padding-bottom: 30px;
                  .button{
                    border-radius: 10px;
                    margin-bottom: 5px;
                  }
                }
            }
            &>.right{
              overflow-y: auto;
              overflow-x: hidden;
            }
            .right{
       
              // width: 100%;
              .activity_view_head{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                font-family: 'MontserratBold';
                font-size: 25px;
                margin-bottom: 20px;
              }
              .activity_time_sec{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                .time_left{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  background-color: #f9f9f9;
                  border: 1px solid #e3e3e3;
                  padding: 10px;
                  border-radius: 10px;
                  // width: 340px;
                  width: calc(100% - 180px);
                  justify-content: space-between;
                  .title{
                    font-family: 'MontserratBold';
                    color: #7e7e7e;
                    font-size: 16px;
                  }
                  .adjust_sec{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 10px;
                    .dec, .inc{
                      color: #686868;
                      background-color: #cbcbcb;
                      font-size: 14px;
                      padding: 10px;
                      font-family: 'MontserratBold';
                      border-radius: 10px;
                      cursor: pointer;
                      &.disabled{
                        pointer-events: none;
                      }
                      &:hover{
                        background-color: #684d94;
                        color: #ffffff;
                      }
                    }
                    .time_txt{
                      color: #684d94;
                      font-size: 16px;
                      font-family: 'MontserratBold';
                      margin: 0 20px;
                    }
                  }
                }
                .time_right{
                  width: 130px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  .label{
                    font-size: 14px;
                    font-family: 'MontserratBold';
                    color: #7e7e7e;
                    width: 100%;
                    text-align: center;
                    line-height: 25px;
                    margin-top: -10px;
                    &.darkMode{
                      color: #ffffff;
                    }
                  }
                  .toggle_sec{
                    position: relative;
                    &>div{
                      width: 100% !important;
                    }
                    .toggle_labels{
                      display: flex;
                      flex-direction: row;
                      position: absolute;
                      top:0;
                      left: 0;
                      z-index: 10;
                      top:calc(50% + 5px);
                      transform: translateY(-50%);
                      pointer-events: none;
                      .label{
                        width: 50%;
                        font-family: 'MontserratBold';
                        font-size: 12px;
                        text-align: center;
                        color: #ffffff;
                       
                      }
                     /*  &.darkMode{
                        .label{
                            &:nth-child(1){
                                color: #000000;
                            }
                        }
                    } */
                    }
                  }
                
                  line-height: 34.5px;
                  .button{
                    border-radius: 10px;
                    .label{
                      font-size:16px;
                    }
                  }
                }
              }
              .questions_sec{
                .question_group{
                  display: flex;
                  flex-direction: column;
          
                  .qnError{
                    color: #ff0000;
                    font-size: 14px;
                    font-family: 'MontserratLight';
                  }
                  .extra_time_picker{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    .title{
                      color: #7e7e7e;
                      font-size: 14px;
                      font-family: 'MontserratLight';
                    }
                    .extras{
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      .extra{
                        color: #ffffff;
                        background-color: #684d94;
                        font-size: 16px;
                        font-family: 'MontserratBold';
                        padding: 5px 10px;
                        margin: 0 0 0 10px;
                        border-radius: 10px;
                        opacity: 0.5;
                        cursor: pointer;
                        &.active, &:hover{
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
                .question_mini{
                  margin-bottom: 10px;
                }
                margin-bottom: 20px;
              }
              .button{
                border-radius: 10px;
                .label{
                  width: 100%;
                  font-size: 14px;
                  text-align: center;
                }
              }
            }
            ::-webkit-scrollbar {
              display: none;
          }
        }
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .right_area{
        position: relative;
        width: 210px;
        padding: 15px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px #d6d6d6;
        border-radius: 10px;
        overflow-y: auto;
      
    }
    ::-webkit-scrollbar {
        display: none;
    }
}


.react-tags {
  position: relative;
  padding: 0.25rem 0 0 0.25rem;
  // border: 2px solid #afb8c1;
  border-radius: 6px;
  background: #ffffff;
  /* shared font styles */
  font-size: 1rem;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text;
}
.react-tags__search-input{
  border: none !important;
  width: auto !important;
}
.react-tags{
  display: flex;
  flex-direction: column;
  border:1px solid #c4c4c4;
  padding: 5px;
}
.react-tags__selected,
.react-tags__search,
.react-tags__selected{
  display: flex;
}
.react-tags__selected{
  flex-wrap: wrap;
  flex-direction: row;
}
.react-tags__suggestions{
  position: absolute;
  top:100%;
  background-color: #ffffff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 13px #d6d6d6;
  z-index: 10;
  mark{
    margin: 0;
    padding: 0;
  }
  ul{
    list-style-type: none;
    li{
      padding: 10px 15px;
      font-family: 'MontserratLight';
      font-size: 14px;
      cursor: pointer;
      &:hover{
        background-color: #ffed8d;
      }
    }
    mark{
      background-color: transparent;
    }
  }
}
.react-tags__selected-tag{
  padding: 0 10px;
  font-size: 12px;
  background-color: #ffed8d;
  font-family: 'MontserratBold';
  border: none;
  border-radius: 5px;
  // width: 60px;
  height: 30px;
  margin: 0 5px 5px 0;
  flex: 0 auto;
}
.react-tags__search,
.react-tags__search-input{
  position: relative;
  // width: 100px;
  width: 100% !important;
}

.react-tags.is-active {
  border-color: #4f46e5;
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  /* Prevent any clicking on the component */
  pointer-events: none;
  cursor: not-allowed;
}

.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tags__list {
  /* Do not use display: contents, it's too buggy */
  display: inline;
  padding: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
  background-color: #e0e0e0;
  border-radius: 3px;
  padding: 3px 6px;
  margin: 2px;
}

.react-tags__tag {
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:hover {
  color: #ffffff;
  background-color: #4f46e5;
}

.react-tags__tag::after {
  content: '';
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(
    10% 0,
    0 10%,
    40% 50%,
    0 90%,
    10% 100%,
    50% 60%,
    90% 100%,
    100% 90%,
    60% 50%,
    100% 10%,
    90% 0,
    50% 40%
  );
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: #7c7d86;
}

.react-tags__tag:hover::after {
  background-color: #ffffff;
}

.react-tags__combobox {
  display: inline-block;
  /* match tag layout */
  padding: 0.375rem 0.25rem;
  margin-bottom: 0.25rem;
  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1;
}
.react-tag-autocomplete {
  border: 1px solid #ccc;
  padding: 5px;
}

.react-tag-autocomplete .tag {
  background-color: #e0e0e0;
  border-radius: 3px;
  padding: 3px 6px;
  margin: 2px;
}
.react-tags__listbox {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  /* Negate the border width on the container */
  left: -2px;
  right: -2px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0 10px 15px -4px,
    rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.react-tags__listbox-option {
  padding: 0.375rem 0.5rem;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #eaeef2;
}

.react-tags__listbox-option:not([aria-disabled='true']).is-active {
  background: #4f46e5;
  color: #ffffff;
}

.react-tags__listbox-option[aria-disabled='true'] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none;
}

.react-tags__listbox-option[aria-selected='true']::after {
  content: '✓';
  margin-left: 0.5rem;
}

.react-tags__listbox-option[aria-selected='true']:not(.is-active)::after {
  color: #4f46e5;
}

.react-tags__listbox-option-highlight {
  background-color: #ffdd00;
}

.tag-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8px;
  gap: 8px;
  background-color: #00000003;
  margin: 0.25rem 0.5rem 0.5rem 0.25rem;
  justify-content: flex-start;
  border: 1px solid #e2e2e2;
  align-items: flex-start;
  border-radius: 4px;
}

.tag-group ul {
  margin: 0;
}

.tag-group > p {
  margin: 0;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: #00000080;
}