@import '../../../styles/global.scss';
.copyright{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    font-family: 'MontserratLight';
    font-size: 12px;
    color: #181818;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    &.darkMode{
        background-color: #181818;
        color: #ffffff;
        .title{
            color: #ffffff;
        }
        .links{
            .link{
                color: #ffffff;
                &#privacy{
                    border-left: 1px solid #ffffff;
                    border-right: 1px solid #ffffff;
                  }
            }
          
        }
    }
    .inline{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .title{
        text-align: center;
        font-family: 'MontserratRegular';
        font-size: 14px;
        color: #000000;
        // @include for-mobile-portrait-screen{
        //   font-size: 3.5vw;
        // }
        // @include for-tab-portrait-screen{
        //   font-size: 3vw;
        // }
      }
    .links{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 0;
        // @include for-mobile-portrait-screen{
        //   margin-top: 1vw;
        // }
        .link{
          font-family: 'MontserratRegular';
          cursor: pointer;
          color: #000000;
          font-size: 12px;
          padding: 0 10px;
          margin: 5px 0;
        //   @include for-mobile-portrait-screen{
        //     font-size: 3.5vw;
        //     padding: 0 2vw;
        //   }
        //   @include for-tab-portrait-screen{
        //     font-size: 3vw;
        //   }
          &#privacy{
    
            border-left: 1px solid #000000;
            border-right: 1px solid #000000;
          }
        }
      }
}