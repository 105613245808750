@import './global.scss';
.bg_cover{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
    img{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
header.landscape_only, footer.landscape_only{
    @include for-mobile-portrait-screen{
        display: none !important;
    }
   
}

.code_screen{
    --parent-display:none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100vw - 100px);
    height: calc(100vh - 10vh);
    top:10vh;
    padding: 25px 50px;
    position: relative;
    @include for-mobile-portrait-screen{
        display: none;
    }
    .left{
        width: calc(60vw - 70px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 16vh - 20px);
        position: relative;
        .video_sec{
            position: relative;
            width: 100%;
            height: calc(75vh - 100px);
            background-color: #684d94;
            border-radius: 20px;
            img{
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%;
                max-width: 150px;
            }
        }
        .landing_code_sec{
            width: calc(100% - 20px);
            border-radius: 20px;
            background-color: #ffed8d;
            padding: 20px 20px 20px 0%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .title{
                font-family: 'MontserratBold';
                font-size: 25px;
                position: relative;
                left: 5%;
            }
            .code_area{
                background-color: #ffffff;
                font-family: 'MontserratBold';
                font-size: 60px;
                padding: 1vh 8vw;
                color: #684d94;
                border-radius: 20px;
            }
        }
        
    }
    .right{
        width: calc(40vw - 70px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 16vh - 20px);
        .logo{
            position: relative;
            width: 80%;
            margin: auto;
            // opacity: 0.01;

            img{
                position: relative;
                width: 100%;
            }
        }
        .qr_sec{
            position: relative;
            width: calc(40vw - 110px);
            height: calc(70vh - 110px);
            background-color: #ffed8d;
            padding: 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &.countdown{
                background-color: #7cc576;
            }
            .title{
                font-family: 'MontserratBold';
                font-size: 20px;
                text-align: center;
                margin-bottom: 20px;
                color: #000000;
                &.countdown{
                    color: #ffffff;
                }
            }
            .code_container{
                width: calc(40vw - 160px);
                height: calc(70vh - 200px);
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 20px;
                canvas{
                    position: relative;
                    margin: auto;
                }
                .countdown_sec{
                    font-family: 'MontserratBold';
                    font-size:28vh;
                    line-height: 26vh;
                }
                .countdown_head{
                    font-family: 'MontserratBold';
                    font-size:4vh;
                }
                .student_url{
                    font-family: 'MontserratBold';
                    font-size:1.8vh;
                    position: absolute;
                    bottom: 12%;
                    width: 85%;
                    text-align: center;
                    color: #684d94;
                }
            }
            
        }
    }
}