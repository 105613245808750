@import './global.scss';

.wait_sec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80%;
    margin: auto;
    .bulb{
        position: relative;
        width: 25vh;
        max-width: 150px;
        margin: auto;
        @include for-mobile-portrait-screen{
            width: 50%;
        }
        img{
            position: relative;
            width: 100%;
        }
    }
    .wait_info{
        font-family: 'MontserratRegular';
        color: #000000;
        font-size: 3vh;
        margin: 4vh 0;
        @include for-mobile-portrait-screen{
            font-size: 4vw;
            margin: 8vw 0;
        }
        @include for-tab-portrait-screen{
            font-size: 3vw;
            margin: 6vw 0;
        }
    }
    .ready_info{
        font-family: 'MontserratRegular';
        color: #000000;
        font-size: 3vh;
        margin-bottom: 8vh;
        @include for-mobile-portrait-screen{
            font-size: 4vw;
            margin-bottom: 8vw;
        }
        @include for-tab-portrait-screen{
            font-size: 3vw;
            margin: 6vw 0;
        }

    }
    .ready_head{
        font-family: 'MontserratBold';
        color: #000000;
        font-size: 6vh;
        margin-top: 4vh;
        @include for-mobile-portrait-screen{
            font-size: 6vw;
            margin-top: 8vw;
        }
        @include for-tab-portrait-screen{
            font-size: 3vw;
            margin: 6vw 0;
        }
    }
    .loading_icons{
        position: relative;
        width: 10vh;
        display: flex;
        animation-name: rotateLoader;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        @include for-mobile-portrait-screen{
            width: 15vw;
        }
        img{
            position: relative;
            width: 100%;
        }
    }
}
@keyframes rotateLoader{
0%{
    transform: rotate(0deg);
}
100%{
    transform: rotate(3600deg);
}
}


.cls-1 {
    fill: #202124;
}

.cls-1, .cls-2, .yellowMask {
    stroke-width: 0px;
}

.cls-2, .yellowMask {
    fill: #fff000;
}