@import '../../../styles/global.scss';

.side_menu{
    position: relative;
    width: 220px;
    @include dashboard-landscape-limit{
        width: 70px;
    }
    min-height: 100%;
    overflow: auto;
    .side_menu_container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        height: 95%;
        .top{
            .button{
                margin-bottom: 5px;
            }
        }
        .bottom{
            background-color: #ffffff;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 1px 1px 13px #d6d6d6;
            &.darkMode{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;

            }
        }
        .button{
            .icon{
                @include dashboard-landscape-limit{
                    margin-right: 0;
                }
            }
            .label{
                @include dashboard-landscape-limit{
                    display: none;
                }
            }
        }
    }
   
}