
.ads_right_area{
    position: relative;
    width: 240px;
    padding: 0 0 15px 0;
    background-color: transparent;
    box-shadow: none;//1px 1px 5px #d6d6d6;
    border-radius: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.darkMode{
        .top,
        .bottom{
            img{
                box-shadow: 1px 1px 5px #181818 !important;
            }
            .label{
                color: #ffffff;
            }
        }
    }
    .recent_title{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        .icon{
            position: relative;
            width: 40px;
            margin-right: 5px;
            display: flex;
            img{
                position: relative;
                width: 100%;
            }
        }
        .label{
            font-family: 'MontserratBold';
            color: #000000;
            font-size: 20px;
        }
        
    }
    .top{
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 1px 1px 5px #d6d6d6;
        a{
            display: flex;
        }
        .carousel{
            max-height: 35vh;
        }
        .control-dots{
            .dot{
                transition: width linear 0.25s;
                transform-origin: center;
                margin: 0 2px;
            }
            .dot.selected{
                background-color: #ffed8d;
                width: 16px;
                border-radius: 20px;
            }
        }
    }
    .bottom{
       
        img{
            position: relative;
            width: 100%;
            border-radius: 20px;
            overflow: hidden;
            box-shadow: 1px 1px 5px #d6d6d6;
        }
        .label{
            font-family: 'MontserratLight';
            color: #000000;
            font-size: 13px;
            text-align: center;
            margin-top: 10px;
        }
    }
    .thoughtfully_mini{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
        
    }
}