@import './global.scss';

.finish_ui{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,0.85);
    z-index: 10;
    .container{
        background-color: #684d94;
        padding: 5vh 2vh;
        @include for-mobile-portrait-screen{
            padding: 6vw 2vw;
        }
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        align-items: center;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        width: 80%;
        max-width: 400px;
        @include for-mobile-portrait-screen{
            max-width: none;
        }
        .finish_icon{
            background-color: #ffed8d;
            display: flex;
            width: 25vh;
            height: 25vh;
            @include for-mobile-portrait-screen{
                width: 40vw;
                height: 40vw;
                margin-bottom: 10vw;
            }
            @include for-tab-portrait-screen{
                width: 30vw;
                height: 30vw;
                margin-bottom: 5vw;
            }
            flex-direction: row;
            justify-content: center;
            border-radius: 50%;
            margin-bottom: 5vh;
            img{
                width: 10vh;
                @include for-mobile-portrait-screen{
                    width: 20vw;
                }
            }
        }
        .title{
            font-family: 'MontserratBold';
            font-size: 3vh;
            width: 90%;
            margin: 1vh auto 0vh auto;
            text-align: center;
            color: #ffffff;
            position: relative;
            @include for-mobile-portrait-screen{
                margin: 1vw auto 1vw auto;
                font-size: 5.5vw;
            }
            @include for-tab-portrait-screen{
                margin: 1vw auto 1vw auto;
                font-size: 4.5vw;
            }
        }
        .info{
            font-family: 'MontserratRegular';
            font-size: 2.5vh;
            width: 90%;
            margin: 2vh auto 2vh auto;
            text-align: center;
            color: #ffffff;
            position: relative;
            @include for-mobile-portrait-screen{
                font-size: 4.5vw;
                margin: 2vw auto 2vw auto;
            }
            @include for-tab-portrait-screen{
                font-size: 3.75vw;
            }
        }
    }
}