// @import '../../styles/constants.scss';

.button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  max-height: 48px;
  .icon_exrta{
    position: relative;
    width: 35px;
    // background-color: white;
    img, svg{
      position: relative;
      width: 100%;

    }
  }
  a{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 48px;
  }
  &.disabled{
    opacity: 0.25;
    pointer-events: none;
  }
  &.disabled2{
    pointer-events: none;
  }

  &.purple-purple{
    background-color: #684d94;
    border: 2px solid #684d94;
    .label{
      color: #ffffff;
      text-align: center;
    }
    &:hover{
      background-color: #ffed8d;
      border: 2px solid #000000;
      .label{
        color: #000000 !important;
      }
     
    }
  }
  &.text-red{
    &:hover{
      .label{
        color: #ff0000;
      }
    }
    .label{
      color: #ff0000;
    }
  }
  &.green{
    background-color: #d4f0b2;
    .label{
      color: #80ab4a;
    }
  }
  &.yellow-black-opaque{
    background-color: #ffed8d;
    // border: 2px solid #000000;
    .label{
      color: #000000;
      text-align: center;
    }
    &.darkMode{
      .label{
        color: #000000 !important;
        text-align: center;
      }
    }
    &:hover{
      background-color: #000000;
      .label{
        color: #ffffff !important;
      }
      .icon svg *{
        fill: #ffffff !important;
      }
    }
  }
  &.yellow-black{
    background-color: #ffed8d;
    border: 2px solid #000000;
    .label{
      color: #000000;
      text-align: center;
    }
    &:hover{
      background-color: transparent;
      .label{
        color: #000000;
      }
    }
  }
  &.white-purple{
    background-color: #ffffff;
    border: 2px solid #684d94;
    .label{
      color: #684d94;
      text-align: center;
    }
    &:hover{
      background-color: #684d94;
      .label{
        color: #ffffff;
      }
    }
  }
  &.purple-white{
    background-color: #684d94;
    border: 2px solid #684d94;
    .label{
      color: #ffffff;
      text-align: center;
    }
    &:hover{
      background-color: #ffffff;
      .label{
        color: #684d94;
      }
    }
  }
  &.darkMode{
    .label{
      color: #ffffff !important;
    }
  }
  &.transparent-red{
    background-color: transparent;
    border: 1px solid #ee4131;
    .label{
      color: #ee4131;
    }
    &:hover{
      background-color: transparent;
      border: 1px solid #ee4131;
      .label{
        color: #ee4131 !important;
      }
      .icon svg *{
        fill: #ee4131 !important;
      }
    }
  }
  &.transparent{
    background-color: transparent;
    border: 1px solid #d4d4d4;
    .label{
      color: #d4d4d4;
    }
    &:hover{
      background-color: #d4d4d4;
      .label{
        color: #000000;
      }
    }
  }
  &.minimal-padding{
    padding: 5px 10px;
    border-radius: 10px;
    .icon{
      margin: 0 5px 0 0;
    }
  }
  &.text-center{
    .label{
      text-align: center;
      width: 100%;
    }
  }
  &.bold-text{
    .label{
      font-family: 'MontserratBold';
    }
  }
  &.regular-text{
    .label{
      font-family: 'MontserratRegular';
    }
  }
  &.yellow{
      background-color: #ffed8d;
      &:hover{
        background-color: #ffffff;
      }
  }
  &.dark_grey{
    &.disabled{
      opacity: 0.25;
    }
    &.no-margin{
      padding: 10px;
      .icon{
        margin-right: 0;
        width: 25px;
      }
    }
    background-color: #e4e4e4;
    &.darkMode{
      background-color: #f9f9f9;
      .label{
        color: #000000 !important;
      }
    }
    &:hover{
      background-color: #f5f5f5 !important;
      &:hover{
        background-color: #000000;
      }
    }
    .label{
      color: #000000;
    }
  }
  &.light_grey{
    &.disabled{
      opacity: 1;
    }
    background-color: #f9f9f9;
    &.darkMode{
      background-color: #f9f9f9;
      .label{
        color: #000000 !important;
      }
    }
    &:hover{
      background-color: #f5f5f5 !important;
      &:hover{
        background-color: #000000;
      }
    }
    .label{
      color: #8d8d8d;
    }
  }
  &.black{
    background-color: #000000;
    &.darkMode{
      background-color: #ffffff;
      .label{
        color: #000000 !important;
      }
    }
    &:hover{
      background-color: #ffed8d !important;
      &:hover{
        background-color: #ffffff;
      }
    }
    .label{
      color: #ffffff;
    }
  }
  &.white_transparent{
    background-color: transparent;
    border: 1px solid #d4d4d4;
    .label{
      color: #ffffff;
    }
    &:hover{
      background-color: #ffffff;
    }
  }
  &.sideBarBottom{
    border-radius: 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #eaeaea;
    &.darkMode{
      border-bottom: 1px solid #000000;
    }
  }
  &.mini{
    .label{
      font-size: 12px;
    }
  }
  &.big_icon{
   .icon{
    width: 50px;
    margin-right: 0;
   }
  }
  .icon{
    position: relative;
    width: 30px;
    margin-right: 20px;
    display: flex;
    img, svg{
      position: relative;
      width: 100%;
    }
    
  }
  .label{
    width: calc(100% - 40px);
    font-size: 16px;
    color: #000000;
    text-align: left;
    font-family: 'MontserratLight';
  }
  &.no-icon{
    .label{
      width: 100% !important;
    }

  }
  &:hover, &.active{
    background-color: #ffed8d;
    .label{
      color: #000000 !important;
    }
    svg *{
      fill: #000000 !important;
    }
  }
}
