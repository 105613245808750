@import './constants.scss';
@import './fonts.scss';
@import './global.scss';
body {
  margin: 0;
  font-size: $fontSize;
  font-family: $fontFamily;
  color: $textDefaultColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}
.dashboard-app{
  &.dark{
    .bg_default{
      background-image: url('../../public/images/BGDark.svg');
      opacity: 1.0;
      
    }
  }
  .bg_default{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-image: url('../../public/images/BG.svg');
    opacity: 0.1;

    background-size: cover;
    
    overflow: hidden;
    svg{
      position: relative;
      width: max(100vw, 150vh);
      height: max(100vw, 150vh);
      object-fit: cover;
      top:50%;
      transform: translateY(-50%);
      // opacity: 0.1;
      // object-fit: cover;
    }
  }
 
}
html, body {
  touch-action: manipulation; /* Prevents zooming and panning */
}
#rotate{
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 40%;
  max-width: 200px;
  img{
    position: relative;
    width: 100%;
  }
  display: none;
  @include for-mobile-portrait-screen{
    display: block;
  }
}
.removed_bg, .connection_lost_bg{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  display: none;
  &.active{
    display: block;
  }
}
:not(:root):fullscreen::backdrop {
background: transparent !important;
}
.removed_info, .connection_lost_info{
  position: fixed;
  top:50%;
  left: 50%;
  width: 80vw;
  max-width: 500px;
  transform: translate(-50%, -50%);
  background-color: #000000;
  z-index: 1000;
  border-radius: 20px;
  padding: 5vh 3vh;
  // margin: auto;
  display: none;
  flex-direction: row;
  justify-content: center;
  @include for-mobile-portrait-screen{
    padding: 10vw 3vw;
  }
  &.active{
    display: flex;
  }
  .close{
      position: absolute;
      width: 3vh;
      height: 3vh;
      padding: 3vh;
      top: -3vh;
      right: -3vh;
      @include for-mobile-portrait-screen{
        width: 5vw;
        height: 5vw;
        padding: 5vw;
        top: -5vw;
        right: -5vw;
    }
      border-radius: 50%;
      overflow: hidden;
      background-color: #684d94;
      display: flex;
     
      img{
          position: relative;
          width: 100%;
      }
  }
  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .profile_img{
        position: relative;
        width: 25vh;
        height: 25vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #684d94;
        border:2px solid #ffffff;
        @include for-mobile-portrait-screen{
          width: 35vw;
          height: 35vw;
          margin-bottom: 5vw;
        }
        img{
            position: relative;
            width: 100%;
            
        }
        .profile{
            display: flex;
            flex-direction: column;
        }
        margin-bottom: 5vh;
    }
    .name{
        color: #ffffff;
        font-family: 'MontserratBold';
        font-size: 4vh;
        text-align: center;
        margin: 1vh 0;
        @include for-mobile-portrait-screen{
          font-size: 5vw;
          margin: 3vw 0;
        }
        
    }
    .removed_label{
        color: #ffffff;
        font-family: 'MontserratRegular';
        font-size: 3vh;
        text-align: center;
        margin: 1vh 0;
        @include for-mobile-portrait-screen{
          font-size: 5vw;
          margin: 3vw 0;
        }
        @include for-tab-portrait-screen{
          font-size: 4vw;
          margin: 32w 0;
        }
    }
    #retryConnection{
        position: relative;
        width: 60%;
        max-width: 400px;
        margin: auto;
        border-radius: 15px;
        font-family: 'MontserratBold';
        color: #ffffff;
        background-color: #684d94;
        font-size: 3vh;
        line-height: 9vh;
        text-align: center;
        text-decoration: none;
        margin-top: 2vh;
        cursor: pointer;
        @include for-mobile-portrait-screen{
            font-size: 5vw;
            line-height: 16vw;
        }
        @include for-tab-portrait-screen{
          font-size: 4vw;
          line-height: 12vw;
        }
    }
}
}
#root{
  position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: none;
    .app{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // min-height: 95vh;
      // top: 5vh;
      position: relative;
      &.extraPadd{
        top:5vh
      }
      position: absolute;
      /* top: 0; */
      width: 100%;
      height: calc(100% - 5vh);
    }
}


main {
  padding: 0px;
  overflow: hidden;
  &.disabled{
    display: none;
  }
}

.heading {
  @include headingStyles()
}

a, .fake-link {
  @include linkStyles()
}

.form {
  max-width: 400px;

  &__button {
    margin-top: 20px
  }
}

.error {
  color: $error;
  font-size: 14px;
}

