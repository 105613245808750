@import '../global.scss';

.help_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .middle_area{
            .head{
                .title{
                    color: #ffffff !important;
                }
                .right{
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                    }
                    ::placeholder{
                        color: #ffffff;
                    }
                }
            }
            .middle_content{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
                .thank_label{
                    color: #ffffff !important;
                }
            }
        }
        .help_sec{
            .field{
                // background-color: #ffffff !important;
                input, textarea{
                    color: #ffffff !important;
                    &:disabled{
                        font-family: 'MontserratLight';
                        opacity: 0.5 !important;
                    }
                }
                ::placeholder{
                    color: #ffffff !important;
                }
            }
            .info{
                color: #ffffff !important;
            }
        }
    }
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
            width: calc(100% - 250px);
          }
          @include dashboard-landscape-limit{
            width: calc(100% - 350px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left{
                .title{
                    font-family: 'MontserratBold';
                    color: #000000;
                    font-size: 30px;
                }
            }
            .right{
                display: flex;
                flex-direction: row;
                input{
                    position: relative;
                    background-color: #ffffff;
                    border: none;
                    padding: 10px 20px;
                    font-family: 'MontserratLight';
                    border-radius: 30px;
                    outline: none;
                    font-size: 16px;
               
                    width: 200px;
                    box-shadow: 1px 1px 13px #d6d6d6;

                }
                .button{
                    margin-left: 20px;
                    position: relative;
                    .label{
                        width: 170px;
                    }
                }
            }
        }
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 75px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .thoughtfully_mini{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .help_sec{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 90%;
                max-width: 450px;
                justify-content: center;
                margin: auto;
                .profile_photo_sec{
                    position: relative;
                    width: 200px;
                    height: 200px;
                    .photo{
                        position: relative;
                        width: 200px;
                        height: 200px;
                        background-color: #d4d4d4;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    .camera_trigger{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #ffffff;
                        box-shadow: 1px 1px 13px #d6d6d6;
                        cursor: pointer;
                        &:hover{
                            background-color: #ffed8d;
                        }
                        img{
                            position: relative;
                            width: 100%;
                        }
                    }
                }
                .field{
                    .rs-input-group-addon{
                        display: none;
                    }
                    .Dropdown-control{
                        padding: 0;
                    }
                    .Dropdown-root{
                        width: 100%;
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 16px;
                        line-height: 40px;
                    }
                    .rs-picker{
                        input{
                            padding: 0;
                        }
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid #d4d4d4;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    position: relative;
                    width: 100%;
                    div{
                        border: 0;
                        outline: none;
                    }
                    .icon{
                        position: relative;
                        height: 25px;
                        margin-left: 10px;
                        img, svg{
                            position: relative;
                            height: 100%;
                        }
                        margin-right: 10px;
                    }
                    input,
                    textarea{
                        width: 100%;
                        background-color: transparent;
                        outline: none;
                        border: 0;
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 16px;
                        line-height: 40px;
                        &:disabled{
                            // opacity: 0.85;
                            font-family: 'MontserratRegular';
                        }
                       
                    }
                    
                    ::placeholder{
                        color: #000000;
                    }
                }
                .info{
                    font-family: 'MontserratLight';
                    color: #000000;
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 15px;
                }
                .password_error{
                    font-family: 'MontserratLight';
                    color: #ff0000;
                    font-size: 16px;
                    text-align: center;
                    margin-top: 10px;
                }
                .inline_fields{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .field{
                        width: 45%;
                    }
                }
                // .button{
                //     margin-top: 10px;
                // }
                .dashboard_settings{
                    margin-top: 100px;
                    position: relative;
                    width: 100%;
                    &.min-margin-top{
                        margin-top: 40px;
                    }
                    .setting_mode{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        justify-content: space-between;
                        margin-top: 10px;
                        .setting_label{
                            font-family: 'MontserratLight';
                            color: #000000;
                            font-size: 16px;
                        }
                        .setting_content{
                            display: flex;
                            flex-direction: row;
                            .fonts{
                              display: flex;
                              flex-direction: row;
                              .font{
                                font-family: 'MontserratBold';
                                font-size: 14px;
                                text-align: center;
                                cursor: pointer;
                                color: #000000;
                                background-color: #dedede;
                                &:hover, &.active{
                                    color: #ffffff;
                                    background-color: #684d94;
                                }
                                margin-right: 10px;
                                padding: 12px 15px;
                                border-radius: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                              }
                            }
                          }
                        .toggle_container{
                            position: relative;
                            &>div{
                              width: 100% !important;
                            }
                            .toggle_labels{
                              display: flex;
                              flex-direction: row;
                              position: absolute;
                              top:0;
                              left: 0;
                              z-index: 10;
                              top:50%;
                              transform: translateY(-50%);
                              pointer-events: none;
                              .label{
                                width: 50%;
                                font-family: 'MontserratBold';
                                font-size: 14px;
                                text-align: center;
                                color: #ffffff;
                               
                              }
                              &.dark_mode{
                                .label{
                                    &:nth-child(1){
                                        color: #000000;
                                    }
                                }
                            }
                            }
                            
                          }
                    }
                }
                .thank_icon{
                    position: relative;
                    width: 80%;
                    max-width: 175px;
                    img{
                        position: relative;
                        width: 100%;
                    }
                    margin-bottom: 50px;
                }
                .thank_label{
                    font-family: 'MontserratRegular';
                    font-size: 20px;
                    text-align: center;
                    color: #000000;
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
        
    }
   
    ::-webkit-scrollbar {
        display: none;
    }
}