@import '../global.scss';

.account_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .middle_area{
            // box-shadow: 1px 1px 13px #262626;
        }
        .middle_content{
            background-color: #181818 !important;
            box-shadow: 1px 1px 13px #262626 !important;
            .head{
                .left{
                    .title{
                        color: #ffffff !important;
                    }
                }
                .right{
                    a{
                        color: #ffffff !important;
                    }
                }
            }
            .account_sec{
                .field{
                    input{
                        color: #ffffff !important;
                    }
                    ::placeholder{
                        color: #ffffff !important;
                    }
                }
                .setting_label{
                    color: #ffffff !important;
                }
                .setting_content{
                    .font{
                        background-color: #ffffff !important;
                        &:hover{
                            background-color: #131313 !important;
                        }
                    }
                }
               
            }
           
        }
        .middle_content{
            .account_sec{
                input{
                    &:disabled{
                        opacity: 0.75 !important;
                    }
                }
            }
        }
    }
   /*  &.darkMode{
        .middle_area{
            
            .head{
                .title{
                    color: #ffffff !important;
                }
                .right{
                    a{
                        color: #ffffff !important;
                        &.active{
                            color: #684d94 !important;
                        }
                    }
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                       
                    }
                    ::placeholder{
                        color: #ffffff;
                    }
                }
            }
            
            .middle_content{
                .account_sec{
                    input{
                        &:disabled{
                            opacity: 0.75 !important;
                        }
                    }
                }
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
                .field{
                    border-bottom: 1px solid #ffffff !important;
                    input, select, .Dropdown-placeholder{
                        color: #ffffff !important;
                    }
                    ::placeholder{
                        color: #ffffff !important;
                    }
                }
                .Dropdown-control,
                .rs-input-group.rs-input-group-inside{
                    background-color: transparent,;
                }
            }
        }
    } */
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
            width: calc(100% - 250px);
          }
          @include dashboard-landscape-limit{
            width: calc(100% - 350px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 90px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .head{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .left{
                    .title{
                        font-family: 'MontserratBold';
                        color: #000000;
                        font-size: 30px;
                    }
                }
                .right{
                    display: flex;
                    flex-direction: row;
                    a{
                        font-family: 'MontserratBold';
                        color: #93949b;
                        font-size: 14px;
                        text-underline-offset: 5px;
                        &:nth-child(2){
                            margin-left: 30px;
                        }
                        &.active{
                            color:#684d94 !important;
                        }
                    }
                }
            }
            .account_sec{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 90%;
                max-width: 450px;
                justify-content: center;
                margin: auto;
                .profile_photo_sec{
                    position: relative;
                    width: 200px;
                    height: 200px;
                    .photo{
                        position: relative;
                        width: 200px;
                        height: 200px;
                        background-color: #d4d4d4;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    .camera_trigger{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #ffffff;
                        box-shadow: 1px 1px 13px #d6d6d6;
                        cursor: pointer;
                        &:hover{
                            background-color: #ffed8d;
                        }
                        img{
                            position: relative;
                            width: 100%;
                        }
                    }
                }
                .field{
                    &.darkMode{
                        .Dropdown-placeholder{
                            color: #ffffff !important;
                            // white-space: nowrap;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // width: 80%;
                        }
                        .Dropdown-menu{
                            background-color: #181818;
                        
                        }
                        
                        .Dropdown-option{
                            color: #ffffff;
                            &.is-selected, &:hover {
                                color: #000000;
                            }
                            // border-bottom: .2px solid #ffffff;
                        }
                    }
                    .rs-input-group-addon{
                        display: none;
                    }
                    .Dropdown-placeholder{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 80%;
                    }
                    .Dropdown-control, .Dropdown-placeholder{
                        padding: 0;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 5px;
                        background-color: transparent;
                    }
                    .Dropdown-menu{
                        max-height: 170px;
                        border: 1px solid #d4d4d4;
                    }
                    .Dropdown-root{
                        width: 243px;
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 16px;
                        height: 40px;
                        line-height: 30px;
                    }
                    .rs-picker{
                        input{
                            padding: 0;
                        }
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid #d4d4d4;
                    margin-bottom: 15px;
                    position: relative;
                    width: 100%;
                    div{
                        border: 0;
                        outline: none;
                    }
                    .icon{
                        position: relative;
                        height: 25px;
                        img, svg{
                            position: relative;
                            height: 100%;
                        }
                        margin-right: 10px;
                    }
                    input{
                        width: 100%;
                        background-color: transparent;
                        outline: none;
                        border: 0;
                        font-family: 'MontserratLight';
                        color: #000000;
                        font-size: 16px;
                        line-height: 40px;
                        &:disabled{
                            opacity: 0.25;
                        }
                       
                    }
                    
                    ::placeholder{
                        color: #000000;
                    }
                }
                .password_error{
                    font-family: 'MontserratLight';
                    color: #ff0000;
                    font-size: 16px;
                    text-align: center;
                    margin-top: 10px;
                }
                .inline_fields{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .field{
                        width: 45%;
                    }
                }
                // .button{
                //     margin-top: 10px;
                // }
                .dashboard_settings{
                    margin-top: 5vh;
                    position: relative;
                    width: 100%;
                    &.min-margin-top{
                        margin-top: 40px;
                    }
                    .setting_mode{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        justify-content: space-between;
                        margin-top: 20px;
                        .setting_label{
                            font-family: 'MontserratLight';
                            color: #000000;
                            font-size: 16px;
                        }
                        .setting_content{
                            display: flex;
                            flex-direction: row;
                            .fonts{
                              display: flex;
                              flex-direction: row;
                              .font{
                                font-family: 'MontserratBold';
                                font-size: 14px;
                                text-align: center;
                                cursor: pointer;
                                color: #000000;
                                background-color: #dedede;
                                &:hover, &.active{
                                    color: #ffffff;
                                    background-color: #684d94;
                                }
                                margin-right: 10px;
                                padding: 12px 15px;
                                border-radius: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                              }
                            }
                            .button{
                                &.transparent-red{
                                    svg{
                                        transform: scale(1.5);
                                    }
                                }
                            }
                          }
                        .toggle_container{
                            position: relative;
                            &>div{
                              width: 100% !important;
                            }
                            .toggle_labels{
                              display: flex;
                              flex-direction: row;
                              position: absolute;
                              top:0;
                              left: 0;
                              z-index: 10;
                              top:50%;
                              transform: translateY(-50%);
                              pointer-events: none;
                              .label{
                                width: 50%;
                                font-family: 'MontserratBold';
                                font-size: 14px;
                                text-align: center;
                                color: #ffffff;
                               
                              }
                              &.dark_mode{
                                .label{
                                    &:nth-child(1){
                                        color: #000000;
                                    }
                                }
                            }
                            }
                            
                          }
                    }
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
    }
}