@import '../global.scss';


.team_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .middle_area{
            
            .head{
                .title{
                    color: #ffffff !important;
                }
                .right{
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                    }
                    ::placeholder{
                        color: #ffffff !important;
                    }
                }
            }
            .middle_content{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
            }
        }
        
 
    }
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
            width: calc(100% - 250px);
          }
        @include dashboard-landscape-limit{
            width: calc(100% - 350px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include dashboard-landscape-limit{
                flex-direction: column;
            }
            .left{
                .title{
                    font-family: 'MontserratBold';
                    color: #000000;
                    font-size: 30px;
                }
            }
            .right{
                display: flex;
                flex-direction: row;
                
                @include dashboard-landscape-limit{
                    justify-content: flex-end;
                    margin: 10px 0;
                }
                input{
                    position: relative;
                    background-color: #ffffff;
                    border: none;
                    padding: 10px 20px;
                    font-family: 'MontserratLight';
                    border-radius: 30px;
                    outline: none;
                    font-size: 16px;
               
                    width: 200px;
                    box-shadow: 1px 1px 13px #d6d6d6;

                }
                .button{
                    margin-left: 20px;
                    position: relative;
                    .label{
                        width: 120px;
                    }
                }
            }
        }
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 100px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            .thoughtfully_mini{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
        
    }
   
    ::-webkit-scrollbar {
        display: none;
    }
}

.create_team{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #684d94af;
    z-index: 1000;
    .right{
        min-width: 16%;
    }
    &.darkMode{
        .container{
            background-color: #181818 !important;
            .head{
                .title{
                    color: #ffffff;
                }
            }
            .field{
                background-color: #454545 !important;
                border: 1px solid #434343 !important;
                
            }
            input, select, .user_email, .access_field select{
                color: #ffffff !important;
            }
            ::placeholder{
                color: #ffffff !important;
            }
        }
        background-color: rgba(0,0,0,.85);
    }
   
    .close {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 32px;
        height: 32px;
        opacity: 1;
        cursor: pointer;
        transition: transform linear 0.1s;
      }
      .close:hover {
        opacity: 1;
        transform: scale(1.1);
      }
      .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 5px;
        background-color: #ffffff;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    .container{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px;
        width: 80%;
        max-width: 500px;
        .head{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .title{
                color: #000000;
                font-family: 'MontserratBold';
                font-size: 20px;
            }
            .closeBtn{
                position: relative;
                width: 40px;
                height: 40px;
                img{
                    position: relative;
                    width: 100%;
                }
            }
        }
        .body{
            margin-top: 10px;
            max-height: 60vh;
            width: 100%;
            overflow-y: auto;
            overflow-x: visible;
            .field{
                
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: transparent;
                border: 1px solid #d4d4d4;
                margin-bottom: 15px;
                position: relative;
                width: calc(100% - 2px);
                border-radius: 10px;
                overflow: hidden;
                div{
                    border: 0;
                    outline: none;
                }
                .icon{
                    position: relative;
                    height: 25px;
                    img{
                        position: relative;
                        height: 100%;
                    }
                    margin-right: 10px;
                }
                input{
                    width: calc(100% - 42px);
                    background-color: transparent;
                    outline: none;
                    border: 0;
                    font-family: 'MontserratLight';
                    color: #000000;
                    font-size: 16px;
                    padding: 0 20px;
                    line-height: 40px;
                }
                ::placeholder{
                    color: #000000;
                }
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
    }
}
::placeholder{
    color: #000000 !important;
}