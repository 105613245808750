@import './global.scss';
.bg_cover{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
    img{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
header.landscape_only, footer.landscape_only{
    @include for-mobile-portrait-screen{
        display: none !important;
    }
   
}
.response_container{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:45%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include for-mobile-portrait-screen{
        display: none;
    }
    // width: 80%;
    .title{
        color: #000000;
        font-family: 'MontserratBold';
        font-size: 4vh;
        text-align: center;
        margin-bottom: 5vh;
    }
    .response_sec{
        display: flex;
        flex-direction: row;
        border: 3px solid #ffed8d;
        background-color: #ffee8d8d;
        padding: 5vh 5vw;
        position: relative;
        border-radius: 20px;

        .left{
            .profile_img{
                position: relative;
                width: 15vw;
                background-color: #684d94;
                height: 15vw;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5vw;
                img{
                    position: relative;
                    width: 100%;
                }
            }
        }
        .right{
            position: relative;
            width: 40vw;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title{
                color: #684d94;
                font-size: 2.2vw;
                line-height: 3vw;
                font-family: 'MontserratBold';
                text-align: center;
            }
            .explanation{
                color: #000000;
                font-size: 1.5vw;
                font-family: 'MontserratBold';
                text-align: center;
                max-height: 25vh;
                overflow-y: auto;
            }
            ::-webkit-scrollbar {
                display: none;
              }
        }
    }
    .navs{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 1vw;
        // pointer-events: none;
        .nav{
            margin: 0 0.5vw;
        }
    }
}
.nav_btns{
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events: none;
    .nav_btn{
        background-color: #684d94;
        pointer-events: all;
        cursor: pointer;
        &.disabled{
            cursor: none;
            pointer-events: none;
            opacity: 0.5;
        }
        img{
            position: relative;
            width: 2vw;
            padding: 2.5vw 2.5vw;
        }
        &.nav_prev{
            border-radius: 0 15px 15px 0;
        }
        &.nav_next{
            border-radius: 15px 0 0 15px;
        }
    }
}