.newpassword_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100%);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;

    .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 40%;
        .logo{
            position: relative;
            width: 80%;
            max-width: 870px;
            img{
                position: relative;
                width: 100%;
            }
        }
    }
    .right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60%;
      
        .newpassword_area{
            background-color: #684d94;
            border-radius: 20px;
            padding: 50px 80px;
            width: calc(100% - 200px);
            max-width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .head{
                .title{
                    font-family: 'MontserratBold';
                    color: #ffffff;
                    font-size: 40px;
                }
                .redirect_sec{
                    font-family: 'MontserratLight';
                    color: #ffffff;
                    font-size: 16px;
                    a{
                        font-family: 'MontserratBold';
                        color: #ffed8d;
                    }
                }
                margin-bottom: 20px;
            }
            .content{
                position: relative;
                width: 100%;
                .field{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: transparent;
                    border-bottom: 1px solid #d4d4d4;
                    margin-bottom: 15px;
                    .icon{
                        position: relative;
                        height: 25px;
                        img, svg{
                            position: relative;
                            height: 100%;
                            *{
                                fill: #ffffff;
                            }
                        }
                        margin-right: 10px;
                    }
                    input{
                        width: 100%;
                        background-color: transparent;
                        outline: none;
                        border: 0;
                        font-family: 'MontserratLight';
                        color: #ffffff;
                        font-size: 16px;
                        line-height: 40px;
                    }
                    ::placeholder{
                        color: #ffffff;
                    }
                }
               
                .buttons{
                    margin-top: 30px;
                    .recover_error{
                        font-family: 'MontserratLight';
                        color: #ff0000;
                        font-size: 14px;
                        text-align: center;
                        margin-top: 10px;
                    }
                    .divider{
                        margin: 10px 0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        span.dash{
                            width: 25%;
                            height: 2px;
                            border-radius: 10px;
                            background-color: #ffffff;
                        }
                        .or{
                            font-family: 'MontserratLight';
                            color: #ffffff;
                            font-size: 16px;
                            margin: 0 5px;
                        }
                    }
                }
                // .button{

                // }
            }
        }
    }
}
.thank_icon{
    position: relative;
    width: 80%;
    max-width: 150px;
    img{
        position: relative;
        width: 100%;
    }
    margin-bottom: 50px;
}
.thank_label{
    font-family: 'MontserratRegular';
    font-size: 20px;
    text-align: center;
    color: #ffffff;
}