@import '../../../styles/global.scss';

.dashboard_header{
    &.dark{
        .head_container{
            .right_controls{
                background-color: #181818;
                box-shadow: 1px 1px 13px #181818;
                .profile_details{
                    .name{
                        color: #ffffff;
                    }
                    .role{
                        color: #b8b8b8;
                    }
                }
                .menu_trigger{
                    background-color: #ffffff;
                }
             
            }
        }
        .szh-menu{
           
            background-color: #181818 !important;
            li{
                
                &:hover{
                    background-color: #ffffff !important;
                    color: #000000 !important;
                }
                color: #ffffff !important;
            }
            .szh-menu__divider{
                background-color: #ffffff !important;
            }
        }
    }
    .head_container{
        position: relative;
        width: calc(100% - 50px);
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 25px;
        .logo{
            position: relative;
            // width: 20%;
            max-width: 200px;
            margin: auto 0;
            @include dashboard-landscape-limit{
                width: 150px;
            }
            img{
              position: relative;
              width: 100%;
            }
        }
        .center{
            
            .more_info{
                border-radius: 15px;
                padding: 20px 15px;
                background-color: #684d94;
                font-family: 'MontserratLight';
                color: #ffffff;
                font-size: 16px;
                @include dashboard-landscape-limit
                {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 15px;
                    max-width: 100vw;
                }
                span.label{
                    @include dashboard-landscape-limit{
                        // display: none;
                        margin-bottom: 10px;
                        text-align: center;
                        font-size: 14px;
                        width: 100%;
                        line-height: 16px;
                    }
                }
                .more_info_link{
                    padding: 5px;
                    @include dashboard-landscape-limit{
                        margin-left: 0px;
                        padding: 5px 10px;
                        font-size: 12px;
                    }
                    background-color: #ffed8d;
                    font-family: 'MontserratBold';
                    a{
                        color: #000000;
                    }
                    padding: 10px;
                    margin-left: 10px;
                    border-radius: 5px;
                }
            }
        }
        .szh-menu.szh-menu--state-open{
            position: relative;
            width: 240px;
       
            a{
                text-decoration: none;
                color: #000000;
               
            }
        }
        .right_controls{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #ffffff;
            padding: 10px 10px;
            border-radius: 5px;
            width: 240px;
            border-radius: 15px;
            box-shadow: 1px 1px 13px #d6d6d6;
           
            .profile_img{
                width: 40px;
                height: 40px;
                // padding: 10px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #684d94;
                &.mini{
                    width: 25px;
                    height: 25px;
                    padding: 7.5px;
                }
                img{
                    position: relative;
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                }
            }
            .profile_details{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                text-align: left;
               
                width: calc(100% - 100px);
                .name{
                    font-family: 'MontserratLight';
                    color: #000000;
                    font-size: 16px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .role{
                    font-family: 'MontserratLight';
                    color: #93949b;
                    font-size: 14px;
                }
                
            }
            .menu_trigger{
                width: 20px;
                height: 20px;
                padding: 10px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    position: relative;
                    width: 100%;
                }
            }
        }
    }
}