.team_mini{
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    width: calc(100% - 42px);
    height: 60px;
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
    &.darkMode{
        background-color: #282828;
        border: 1px solid #434343;
        .thoghtfully_title,
        .info_sub{
            color: #ffffff !important;
        }
        .team_title,
        .team_members{
            color: #ffffff !important;
        }
    }
    &.mini{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        width: calc(100% - 20px);
        padding-left: 20px;
        .left{
        
            .team_title{
                margin-left: 10px;
                font-size: 116px;
                .thoghtfully_title{
                  
                }
                .info_sub{
                    font-size: 12px;
                }
                 .info_footer{
                    display: none;
                }
            }
            
        }
        .right{
         
            .live_btn{
                font-size: 12px;
                border-radius: 5px;
                padding: 7.5px 15px;
            }
            .team_mini_trigger{
                padding: 0 10px 0 10px;
                span{
                    width: 5px;
                    height: 5px;
                    &:nth-child(2){
                        margin: 2px 0;
                    }
                }
            }
            width: 100%;
            justify-content: space-between;
        }
    }
    .left{
        display: flex;
        flex-direction: column;
        // align-items: center;
        width: 80%;
      
        &.inline{
            display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        }
        &.full_size{
            width: 100%;
        }
        .team_title{
            font-family: 'MontserratBold';
            color: #000000;
            font-size: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 80%;
            overflow: hidden;
        }
        .user_email{
            font-family: 'MontserratRegular';
            color: #000000;
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 80%;
            overflow: hidden;
        }
        .team_members{
            font-family: 'MontserratLight';
            color: #000000;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 80%;
            overflow: hidden;
        }
        .email_field{
            position: relative;
            width: 80%;
            input{
                width: 100%;
                font-family: 'MontserratRegular';
                color: #000000;
                font-size: 16px;
                line-height: 30px;
                border: 0;
                outline: none;
                background: transparent;
                position: relative;
                width: 100%;
            }
            select{
                width: 100%;
                font-family: 'MontserratRegular';
                color: #000000;
                font-size: 16px;
                line-height: 30px;
                border: 0;
                outline: none;
                background: transparent;
                position: relative;
                width: 100%;
            }
        }
        .access_field{
            background-color: transparent;
            select{
                background-color: transparent;
                color: #000000;
                font-size: 16px;
                line-height: 30px;
                outline: none;
                padding: 10px;
                border: none;
            }
        }
    }
    .right{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        justify-content: center;
        .addBtn{
            font-family: 'MontserratBold';
            background-color: #ffed8d;
            color: #000000;
            font-size: 12px;
            padding: 10px 20px;
            border-radius: 10px;
            // margin-right: 20px;
            cursor: pointer;
            &:hover{
                background-color: #684d94;
                color: #ffffff;
            }
        }
        .deleteBtn{
            position: relative;
            width: 30px;
            cursor: pointer;
            svg{
                transform: scale(1.5);
            }
            img{
                position: relative;
                width: 100%;
            }
        }
        .extraTimeInfo{
            font-family: 'MontserratBold';
            color: #ffffff;
            background-color: #684d94;
            font-size: 16px;
            padding: 8px 20px;
            border-radius: 20px;
        }
        .team_mini_trigger{
            display: flex;
            flex-direction: column;
            padding: 0 0 0 20px;
            cursor: pointer;
            span{
                width: 7px;
                height: 7px;
                border-radius: 2px;
                background-color: #bababa;
                &:nth-child(2){
                    margin: 4px 0;
                }
            }
        }
    }
}