.confirm_container{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #684d94af;
    z-index: 1000;
       &.darkMode{
        background-color: rgba(0,0,0,.85);
        .popup_container{
            background-color: #181818;
        }
     
    }
    .popup_container{
        position: absolute;
        width: 80%;
        max-width: 1000px;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // background-color: #ffffff;
        padding: 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .close {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 32px;
        height: 32px;
        opacity: 1;
        cursor: pointer;
        transition: transform linear 0.1s;
      }
      .close:hover {
        opacity: 1;
        transform: scale(1.1);
      }
      .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #ffffff;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
}

.confirm_sec{
    &.darkMode{
        background-color: #181818;
        .desc{
            color: #ffffff !important;
        }
    }
    
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    .txt{
        font-family: 'MontserratBold';
        font-size: 30px;
        text-align: center;
        color: #684d94;
    }
    .desc{
        font-family: 'MontserratLight';
        font-size: 16px;
        width: 60%;
        margin: auto;
        text-align: center;
        color: #000000;
        margin: 20px 0;
    }
    .buttons{
       
        display: flex;
        flex-direction: row;
   
        width: 400px;
        // margin: 50px auto 0 auto;
        margin-top: 50px;
        justify-content: space-between;
    }
    margin-bottom: 20px;
}