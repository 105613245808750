@import './global.scss';
.bg_cover{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
    img{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
header.landscape_only, footer.landscape_only{
    @include for-mobile-portrait-screen{
        display: none !important;
    }
   
}
.question_container{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 900px;
    @include for-mobile-portrait-screen{
        display: none;
    }
    .timer_sec{
        color: #684d94;
        background-color: #ffed8d;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        font-family: 'MontserratBold';
        font-size: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: -40px;
        z-index: 2;
    }
    .question_sec{
        border: 3px solid #ffed8d;
        background-color: #ffee8d8d;
        padding: 60px 30px;
        border-radius: 20px;
        font-family: 'MontserratBold';
        font-size: 27px;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
        width: 100%;
    }
    .question_nav{
        border: 3px solid #684d94;
        background-color: #684d94ca;
        padding: 10px 30px;
        border-radius: 10px;
        font-family: 'MontserratBold';
        font-size: 27px;
        color: #ffffff;
    }
}