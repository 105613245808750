.live_popup{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #684d94af;
    z-index: 1000;
    &.darkMode{
        background-color: rgba(0,0,0,.85);
        .popup_container{
            background-color: #181818;
        }
        .QR_sec{
            background-color: #262626 !important;
            .title, .desc{
                color: #ffffff !important;
            }
        }
     
    }
    .close {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 32px;
        height: 32px;
        opacity: 1;
        cursor: pointer;
        transition: transform linear 0.1s;
      }
      .close:hover {
        opacity: 1;
        transform: scale(1.1);
      }
      .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #ffffff;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    .popup_container{
        position: absolute;
        width: 80%;
        max-width: 1000px;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        padding: 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .QR_sec{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ffed8d;
            position: relative;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px 0;
            .title{
                font-family: 'MontserratBold';
                font-size: 20px;
                text-align: center;
                // margin-bottom: 20px;
                color: #000000;
                margin: 10px auto 20px auto;
                &.countdown{
                    color: #ffffff;
                }
            }
            .desc{
                font-family: 'MontserratRegular';
                font-size: 17px;
                text-align: center;
            
                color: #000000;
                &.countdown{
                    color: #ffffff;
                }
            }
            .code_container{
                width: calc(40vw - 160px);
                height: calc(70vh - 200px);
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 20px;
                margin: auto;
                margin-bottom: 20px;
                canvas{
                    position: relative;
                    margin: auto;
                }
                .countdown_sec{
                    font-family: 'MontserratBold';
                    font-size:28vh;
                    line-height: 26vh;
                }
                .countdown_head{
                    font-family: 'MontserratBold';
                    font-size:4vh;
                }
                .student_url{
                    font-family: 'MontserratBold';
                    font-size:1.8vh;
                    position: absolute;
                    bottom: 12%;
                    width: 85%;
                    text-align: center;
                    color: #684d94;
                }
            }
        }
        .video_sec{
            position: relative;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
            video{
                position: relative;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;
            }
            margin-bottom: 20px;
        }
      
      
    }
}