.profile{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    // 
    // overflow: hidden;
    &.full_width{
        width: 100%;
    }
    &.half_width{
        width: 50%;
    }
    &.active{
        .img{
            background-color: #ffed8d;
            // border: 2px solid #ffed8d;
        }
    }
    .img{
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid #ffffff;
        background-color: #000000;
        img{
            position: relative;
            width: 100%;
       
        }
    }
   
    
    .studentName{
        font-family: 'MontserratBold';
        font-size: 18px;
        color: #ffffff;
        white-space:nowrap; 
        overflow: hidden;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
  }