@font-face {
    font-family: 'MontserratLight';
    src: url('../../public/fonts/MontserratLight.otf');
}
@font-face {
    font-family: 'MontserratRegular';
    src: url('../../public/fonts/Montserrat-Regular.otf');
}
@font-face {
    font-family: 'MontserratBold';
    src: url('../../public/fonts/Montserrat-Bold.otf');
}
@font-face {
    font-family: 'LUBALINGRAPHITCTURNERARABD';
    src: url('../../public/fonts/LUBALINGRAPHITCTURNERARA-BD.TTF');
  }