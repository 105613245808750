@import '../../styles/global.scss';
.footer {

  position: fixed;
  width: 100%;

  bottom: 30px;
  margin: auto;
  &.move_extra{
    bottom: 100px;
  }
 
  .timer_sec, .skipBtn{
    display: flex;
    flex-direction: row;
    background-color: #684d94;
    width: calc(48% - 6vw);
    max-width: 250px;
    border-radius: 10vw;
    align-items: center;
    justify-content: center;
    font-family: 'MontserratBold';
    font-size: 2.5vh;
    color: #ffffff;
    line-height: 8vh;
    &.skipBtn{
      background-color: #dadada !important;
      color: #000000;
      cursor: pointer;
    }
    @include for-mobile-portrait-screen{
      width: calc(48% - 6vw);
      border-radius: 10vw;
      font-size: 4vw;
      line-height: 12vw;
      max-width: none;
    }
    @include for-tab-portrait-screen{
      font-size: 3vw;
      line-height: 10vw;
    }
  }
  .profile_sec{
    display: flex;
    flex-direction: row;
    background-color: #000000;
    padding: 1vh 1vh;
    width: calc(48% - 6vw);
    max-width: 250px;
    justify-content: space-between;
    border-radius: 10vw;
    @include for-mobile-portrait-screen{
      padding: 2vw 2vw;
      width: calc(48% - 6vw);
    }
    .icon{
      position: relative;
      width: 6vh;
      @include for-mobile-portrait-screen{
        width: 8vw;
      }
      img{
        position: relative;
        width: 100%;
      }
    }
  }
  &.normal{
    position: relative;
    margin: 0;
    bottom: 2.5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include for-mobile-portrait-screen{
      bottom: 10px;
      flex-direction: column;
    }
  }
  &.inline{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0vh;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    @include for-mobile-portrait-screen{
      justify-content: space-evenly;
      width: 100%;
      margin-top: 2vw;
    }
  }
  &>.title{
    text-align: center;
    font-family: 'MontserratRegular';
    font-size: 2.25vh;
    color: #000000;
    &.bold{
      a{
        font-family: 'MontserratBold';
        color: #000000;
      }
    }
    @include for-mobile-portrait-screen{
      font-size: 3.5vw;
    }
    @include for-tab-portrait-screen{
      font-size: 3vw;
    }
  }
  .links{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
    @include for-mobile-portrait-screen{
      margin-top: 1vw;
    }
    .link{
      font-family: 'MontserratRegular';
      cursor: pointer;
      color: #000000;
      font-size: 2.25vh;
      padding: 0 1.5vh;
      @include for-mobile-portrait-screen{
        font-size: 3.5vw;
        padding: 0 2vw;
      }
      @include for-tab-portrait-screen{
        font-size: 3vw;
      }
      &#privacy{

        border-left: 1px solid #000000;
        border-right: 1px solid #000000;
      }
    }
  }
  .footer_container{
    width: calc(100% - 100px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    align-items: flex-end;
    .sec1{
      position: relative;
      width: 100%;
      .code_sec{
        // width: 23vw;
        min-width: 300px;
        border-radius: 15px;
        background-color: #dadada;
        padding: 15px 15px 15px 0%;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          left: 5%;
          position: relative;
        }
        .code_container{
          background: #ffffff;
          padding: 8px;
          display: flex;
          border-radius: 15px;
        }
        .footer_code_title{
            font-family: 'MontserratBold';
            font-size: 23px;
            position: relative;
        
            // margin-right: 5%;
        }
        .footer_code_url{
          font-family: 'MontserratBold';
          font-size: 15px;
          position: relative;
          color: #684d94;
          width: 25vw;
          margin-top: 10px;
          // left: 5%;
        }
        .code_area{
            background-color: #ffffff;
            font-family: 'MontserratBold';
            font-size: 50px;
            padding: 5px 20px;
            color: #684d94;
            border-radius: 15px;
        }
    }
    }
    .sec2{
      position: relative;
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .logo{
        width: 100%;
        max-width: 300px;
        img{
          position: relative;
          width: 100%;
        }
      }
    }
  }
  
}

