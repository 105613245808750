@import '../global.scss';

.faq_container{
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 120px);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    &.darkMode{
        .faq_multiple{
            &>.title{
                color: #ffffff;
            }
            
        }
        .middle_area{
            .head{
                .title{
                    color: #ffffff !important;
                }
                .info{
                    color: #ffffff !important;
                }
                .right{
                    input{
                        background-color: #454545;
                        color: #ffffff;
                        box-shadow: 1px 1px 13px #454545;
                    }
                    ::placeholder{
                        color: #ffffff;
                    }
                }
            }
            .middle_content{
                background-color: #181818;
                box-shadow: 1px 1px 13px #262626;
            }
        }
        .faq_sec{
            .faq{
                .card-title{
                    background-color: #282828 !important;
                    color: #ffffff;
                }
            }
        }
    }
    .middle_area{
        position: relative;
        width: calc(100% - 500px);
        &.full_size{
            width: calc(100% - 250px);
          }
          @include dashboard-landscape-limit{
            width: calc(100% - 350px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left{
                .title{
                    font-family: 'MontserratBold';
                    color: #000000;
                    font-size: 30px;
                }
                .info{
                    font-family: 'MontserratLight';
                    color: #000000;
                    font-size: 14px;
                }
            }
            .right{
                display: flex;
                flex-direction: row;
                input{
                    position: relative;
                    background-color: #ffffff;
                    border: none;
                    padding: 10px 20px;
                    font-family: 'MontserratLight';
                    border-radius: 30px;
                    outline: none;
                    font-size: 16px;
               
                    width: 200px;
                    box-shadow: 1px 1px 13px #d6d6d6;

                }
                .button{
                    margin-left: 20px;
                    position: relative;
                    .label{
                        width: 170px;
                    }
                }
            }
        }
        .middle_content{
            border-radius: 10px;
            background-color: #ffffff;
            height: calc(100% - 70px);
            padding: 20px 20px 30px 20px;
            box-shadow: 1px 1px 5px #d6d6d6;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .thoughtfully_mini{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .faq_multiple{
                &>.title{
                    margin: 0;
                    font-family: 'MontserratBold';
                    padding: 20px 0 10px 0px;
                    font-size: 16px;
                }
                .faq_sec{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    // margin-top: 20px;
                    // max-width: 450px;
                    // justify-content: center;
                    // margin: auto;
                    .faq{
                        &.disabled{
                            pointer-events: none;
                        }
                        width: 100%;
                        margin-bottom: 10px;
                        @include for-mobile-portrait-screen{
                            margin-bottom: 0.5vw;
                        }
                        .list-group-item{
                            
                            border-radius: 20px;
                            @include for-mobile-portrait-screen{
                                border-radius: 10vw;
                            }
                            background-color: #684d94;
                            .card-title {
                                cursor: pointer;
                                background-color: #e8e8e8;
                                margin: 0;
                                font-family: 'MontserratRegular';
                                padding: 20px 20px;
                              
                                border-radius: 15px;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                               h2{
                                font-size: 16px;
                                line-height: 20px;
                                text-align: left;
                               }
                              }
                              .card-collapse {
                                overflow: hidden;
                                transition: height 0.3s ease-out;
                                background-color: #684d94;
                                border-radius: 20px;
                                .card-body{
                                    color: #ffffff;
                                    padding: 20px;
                                    font-size: 16px;
                                    font-family: 'MontserratRegular';
                                }
                              }
                        }
                    }
                   
                }
            }
         
        }
        ::-webkit-scrollbar {
            display: none;
        }
        
    }
   
    ::-webkit-scrollbar {
        display: none;
    }
}