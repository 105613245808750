@import './global.scss';
@media only screen and (min-device-width: 768px) and (max-device-width: 1400px) and (orientation: landscape) {
.thought_sec{
    max-height: 70vh;
}
  }
.thought_sec{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    
    margin: auto;
  
    
    overflow: auto;
    @include for-mobile-portrait-screen{
        max-height: 70vh;
    }
    .title{
        font-family: 'MontserratBold';
        width: 90%;
        // max-width: 500px;
        font-size: 3.5vh;
        margin: 4vh auto;
        text-align: center;
        color: #000000;
        position: relative;
        @include for-mobile-portrait-screen{
            font-size: 6vw;
            margin: 5vw auto;
        }
        @include for-tab-portrait-screen{
            font-size: 4vw;
            margin: 5vw auto;
        }
    }
    .opinions{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 550px;
        width: 100%;
        margin: auto;
        .opinion{
            font-family: 'MontserratBold';
            font-size: 2vh;
            padding: 2.5vh 0;
            width: 100%;
            background-color: #dadada;
            color: #000000;
            text-align: center;
            border-radius: 6vw;
            margin-bottom: 1vh;
            @include for-mobile-portrait-screen{
                font-size: 3vw;
                padding: 4vw 0;
                margin-bottom: 1.5vw;
            }
            @include for-tab-portrait-screen{
                padding: 3vw 0;
                font-size: 2.75vw;
            }
            cursor: pointer;
            &:hover, &.active{
                background-color: #684d94;
                color: #ffffff;
            }
        }
    }
    #confirmThought, #confirmExplanation{
        position: relative;
        width: 80%;
        margin: auto;
        border-radius: 15px;
        font-family: 'MontserratBold';
        color: #000000;
        background-color: #ffed8d;
        font-size: 3vh;
        line-height: 9vh;
        text-align: center;
        margin-top: 1vh;
        cursor: pointer;
        &.disabled{
            opacity: 0.25;
            cursor: none;
        }
        max-width: 400px;
        @include for-mobile-portrait-screen{
            font-size: 5vw;
            line-height: 14vw;
            margin-top: 3vw;
            max-width: none;
        }
        @include for-tab-portrait-screen{
            font-size: 3vw;
            line-height: 12vw;
        }
    }
}
::-webkit-scrollbar {
    display: none;
}
.explanation_ui, .picked_ui{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.85);
    z-index: 10;
    &.picked_ui{
        .container{
            background-color: #000000;
            .title{
                color: #ffed8d;
            }
        }
    }
    .container{
        background-color: #684d94;
        padding: 6vh 2vh;
        @include for-mobile-portrait-screen{
            padding: 6vw 2vw;
        }
        display: flex;
        flex-direction: column;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        width: 80%;
        max-width: 400px;
        @include for-mobile-portrait-screen{
            max-width: none;
        }
        .profile_img{
            .profile{
                display: flex;
                flex-direction: column;
            }
         
        }
        .close{
            position: absolute;
            width: 3vh;
            height: 3vh;
            padding: 3vh;
            top: -3vh;
            right: -3vh;
            @include for-mobile-portrait-screen{
              width: 5vw;
              height: 5vw;
              padding: 5vw;
              top: -5vw;
              right: -5vw;
          }
          @include for-tab-portrait-screen{
            width: 3.5vw;
            height: 3.5vw;
            padding: 3.5vw;
            top: -3.5vw;
            right: -3.5vw;
          }
            cursor: pointer;
            border-radius: 50%;
            overflow: hidden;
            background-color: #000000;
            display: flex;
    
            &:hover{
                background-color: #684d94;
            }
            img{
                position: relative;
                width: 100%;
               
            }
        }
        .title{
            font-family: 'MontserratBold';
            font-size: 3vh;
            width: 90%;
            margin: 3vh auto 3vh auto;
            text-align: center;
            color: #ffffff;
            position: relative;
            @include for-mobile-portrait-screen{
                margin: 5vw auto 5vw auto;
                font-size: 5.5vw;
            }
            @include for-tab-portrait-screen{
                font-size: 4vw;
            }
        }
        .info{
            font-family: 'MontserratRegular';
            font-size: 2.5vh;
            width: 90%;
            margin: 5vh auto 5vh auto;
            text-align: center;
            color: #ffffff;
            position: relative;
            @include for-mobile-portrait-screen{
                font-size: 4.5vw;
                margin: 5vw auto 5vw auto;
            }
        }
        .explanation{
            padding: 4vh;
            background-color: #ffffff;
            border-radius: 10px;
            width: 80%;
            margin: auto;
            position: relative;
            @include for-mobile-portrait-screen{
                padding: 4vw;
            }
            textarea{
                font-family: 'MontserratRegular';
                font-size: 2.5vh;
                line-height: 3.5vh;
                color: #000000;
                outline: none;
                width: 100%;
                max-height: 30vh;
                border: 0;
                border-radius: 0;
                background: transparent;
                @include for-mobile-portrait-screen{
                    font-size: 3.5vw;
                    line-height: 5vw;
                }
            }
            // max-height: 50vw;
            overflow: auto;
            ::-webkit-scrollbar {
                display: none;
            }
        }
        #confirmExplanation{
            position: relative;
            width: 80%;
            margin: auto;
            border-radius: 15px;
            font-family: 'MontserratBold';
            color: #000000;
            background-color: #ffed8d;
            font-size: 3vh;
            line-height: 8vh;
            text-align: center;
            margin-top: 3vh;
            cursor: pointer;
            &:hover{
                background-color: #000000;
                color: #ffffff;
            }
            &.disabled{
                opacity: 0.25;
                cursor: none;
            }
            @include for-mobile-portrait-screen{
                font-size: 5vw;
                line-height: 14vw;
                margin-top: 3vw;
            }
            @include for-tab-portrait-screen{
                font-size: 4vw;
            }
        }
    }
}